import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { Params } from "../../../@types/api/api.types";
const { get } = request;

const initialRoute = "dashboard";
export const getdashboardStats = async (filterQuery: Params) => {
	try {
		const endpoint = `${initialRoute}/get-dashboad-stats`;
		const response = await get(
			endpoint,
			{
				...headers
			},
			filterQuery
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}

		throw new Error();
	} catch (error) {
		console.error("Error while get dashboard stats:", error);
		throw error;
	}
};
