import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";
const PhoneCellRenderer = ({ value }: { value: string }) => {
	const [msg, setMessage] = useState<string>("Copy");
	const copyHandler = async () => {
		try {
			await navigator.clipboard.writeText(value);
			setMessage("Copied !");
			setTimeout(() => {
				setMessage("Copy");
			}, 1000);
		} catch (error) {
			console.error("Failed to copy phone number: ", error);
		}
	};
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			}}
		>
			<a href={`tel:${value}`} style={{ color: "green" }}>
				{value}
			</a>
			<div>
				<Tooltip title={msg} placement="right">
					<IconButton>
						<ContentCopyIcon
							fontSize="small"
							sx={{
								color: "grey",
								cursor: "pointer"
							}}
							onClick={copyHandler}
						/>
					</IconButton>
				</Tooltip>
			</div>
		</div>
	);
};

export default PhoneCellRenderer;
