import React, { useCallback, useState } from "react";
import { Grid, Paper, TextField, Box, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { IViewUserProps } from "../../../../../@types/props/ViewUser.props";
import { api } from "../../../../../utils/api";
import SnackbarMessage from "../../../../shared/snackbar/SnackbarMessage";

function PersonalDetailsForm({ user }: IViewUserProps) {
	const queryParams = new URLSearchParams(window.location.search);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const userId = queryParams.get("_id");
	const [personalDetails, setPersonalDetails] = useState({
		full_name: user?.full_name || "",
		email: user?.email || "",
		mobile: user?.mobile || "",
		age: user?.age || "",
		gender: user?.gender || "",
		marital_status: user?.marital_status || "",
		country: user?.country || "",
		state: user?.state || "",
		height: user?.height || "",
		weight: user?.weight || "",
		body_color: user?.body_color || "",
		eye_color: user?.eye_color || "",
		hair_color: user?.hair_color || ""
	});
	const [editable, setEditable] = useState(true);

	const handlePersonalDetailsChange = (event: any) => {
		const { id, value } = event.target;
		setPersonalDetails({ ...personalDetails, [id]: value });
	};

	const handleEditClick = () => {
		setEditable(true);
	};

	const [profilePhoto, setProfilePhoto] = useState<File | null>(null);

	const handleUpload = async () => {
		console.log("====>called");
		if (user && user._id && profilePhoto !== null) {
			try {
				// Create FormData and append the file and user ID
				const formData = new FormData();
				formData.append("profile_image", profilePhoto);
				formData.append("userObjectId", user._id);

				// Log FormData entries for verification

				const response = await api.user.updateUserImage(formData);
				console.log("===>image updated");
			} catch (error) {
				console.log("Upload error:", error);
			} finally {
			}
		} else {
		}
	};

	const handleSaveClick = useCallback(async () => {
		setEditable(false);
		console.log("save clicked--->", personalDetails);
		const payload = { userDetails: personalDetails, userObjectId: userId };
		console.log("Changes value---->", personalDetails);
		const response = await api.user.updateUserDetails(payload);

		if (response) {
			setSnackbarMessage("Personal details Edited successfully!");
			setSnackbarOpen(true);
		}
		console.log("Response --->", response);
	}, [personalDetails, userId]);

	return (
		<Box>
			<div className="edit-btn-container">
				<Button
					onClick={handleUpload}
					variant="contained"
					className="button-violet"
					endIcon={<SaveIcon />}
					disabled={!editable}
				>
					Save User Profile Picture
				</Button>
			</div>
			<Paper elevation={3} sx={{ padding: 3, maxWidth: "100%", margin: "auto", paddingLeft: 5 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6}>
						<Box component="label" htmlFor="full_name" sx={{ fontWeight: "bold" }}>
							Profile Picture:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<div className="mt-5">
							<TextField
								type="file"
								variant="outlined"
								fullWidth
								InputLabelProps={{ shrink: true }}
								inputProps={{ accept: "image/*" }}
								onChange={(event: any) => {
									const file = event.target.files?.[0];
									if (file) {
										console.log("Selected file:", file);
										setProfilePhoto(file);
									}
								}}
								label="Upload Profile Photo"
								sx={{ mt: 2, mb: 1 }}
							/>
							{/* <button
								style={{
									marginTop: 20,
									marginBottom: 10,
								}}
								role={undefined}
								tabIndex={-1}
							>
								<input
									type="file"
									onChange={(event) => {
										const file = event.target.files?.[0];
										if (file) {
											console.log("Selected file:", file);
											setProfilePhoto(file);
										}
									}}
									required
								/>
							</button> */}
						</div>
					</Grid>
				</Grid>
			</Paper>
			<div className="edit-btn-container" style={{ marginTop: 40 }}>
				{/* <Button
					onClick={handleEditClick}
					sx={{ marginRight: 1 }}
					variant="contained"
					className="button-violet"
					endIcon={<EditIcon />}
					disabled={editable}
				>
					Edit
				</Button> */}

				<Button
					onClick={handleSaveClick}
					variant="contained"
					className="button-violet"
					endIcon={<SaveIcon />}
					disabled={!editable}
				>
					Save User Details
				</Button>
			</div>
			<h3>Personal Details</h3>
			<Paper elevation={3} sx={{ padding: 3, maxWidth: "100%", margin: "auto", paddingLeft: 5 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6}>
						<Box component="label" htmlFor="full_name" sx={{ fontWeight: "bold" }}>
							Full Name:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="full_name"
							fullWidth
							variant="outlined"
							value={personalDetails.full_name}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="email" sx={{ fontWeight: "bold" }}>
							Email:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="email"
							variant="outlined"
							value={personalDetails.email}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="mobile" sx={{ fontWeight: "bold" }}>
							Mobile:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="mobile"
							variant="outlined"
							value={personalDetails.mobile}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="age" sx={{ fontWeight: "bold" }}>
							Age:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="age"
							variant="outlined"
							value={personalDetails.age}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="gender" sx={{ fontWeight: "bold" }}>
							Gender:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="gender"
							variant="outlined"
							value={personalDetails.gender}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="marital_status" sx={{ fontWeight: "bold" }}>
							Marital Status:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="marital_status"
							variant="outlined"
							value={personalDetails.marital_status}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="country" sx={{ fontWeight: "bold" }}>
							Country:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="country"
							variant="outlined"
							value={personalDetails.country}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="state" sx={{ fontWeight: "bold" }}>
							State:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="state"
							variant="outlined"
							value={personalDetails.state}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="height" sx={{ fontWeight: "bold" }}>
							Height (cm):
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="height"
							variant="outlined"
							value={personalDetails.height}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="weight" sx={{ fontWeight: "bold" }}>
							Weight (kg):
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="weight"
							variant="outlined"
							value={personalDetails.weight}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="body_color" sx={{ fontWeight: "bold" }}>
							Body Color:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="body_color"
							variant="outlined"
							value={personalDetails.body_color}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="eye_color" sx={{ fontWeight: "bold" }}>
							Eye Color:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="eye_color"
							variant="outlined"
							value={personalDetails.eye_color}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="hair_color" sx={{ fontWeight: "bold" }}>
							Hair Color:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="hair_color"
							variant="outlined"
							value={personalDetails.hair_color}
							onChange={handlePersonalDetailsChange}
							disabled={!editable}
						/>
					</Grid>
				</Grid>
			</Paper>
			<SnackbarMessage
				snackbarOpen={snackbarOpen}
				snackbarMessage={snackbarMessage}
				severity={snackbarSeverity}
				handleClose={() => setSnackbarOpen(false)}
			/>
		</Box>
	);
}

export default PersonalDetailsForm;
