import { loginAdmin } from "./auth/loginAdmin";
import { getOtp } from "./auth/otpAdmin";
import { addBanner } from "./banner/postBanner";
import { forwardEnqury, getBooking, getForwardEnquryList, updateEnquiryStatus } from "./booking/booking";
import { getBuilder } from "./builders/getBuilder";
import { getBuilderByID } from "./builders/getBuilderByID";
import { addBuilder } from "./builders/postBuilder";
import { getCustomer } from "./customer/getCustomer";
import { getEmployeeList } from "./employee/getEmployee";
import { getUsers } from "./user/getUser";
import { createAdmin } from "./permission/userManagement/createAdmin";
import { getBuilderList } from "./permission/userManagement/getBuilderList";
import { addPlot } from "./projects/addProject";
import { adminAssignProject } from "./projects/assignProject";
import { getAdminPlots, getAssignedProjectList, getProjectDetails } from "./projects/getAdminProjects";
import { getAllProjects, getPlots } from "./projects/getProjects";
import { updatePlotPostion } from "./projects/updatePlotPosition";
import { getProjectList } from "./projects/getProjectList";
import { addProjectFacility } from "./projects/addProjectFacility";
import { getProjectFacilities } from "./projects/getProjectFacilities";
import { deleteProjectFacility } from "./projects/deleteProjectFacility";
import { updateProject } from "./projects/updateProject";
import { getBookingDetails, getEnquiryDetails } from "./projects/getEnquiryDetails";
import { createFollowUp } from "./followup/createFollowup";
import { getFollowUp } from "./followup/getFollowUp";
import { getResellerEnquiry } from "./resellerEnquiry/getResellerEnquiry";
import { getAdminAndBuilder } from "./projects/getAdminAndProjects";
import { addBank } from "./bank/postBank";
import { getBankContacts } from "./bank/getBankContacts";
import { getUpcomingProjects } from "./upcomingProjects/getUpcomingProjects";
import { addUpcomingProject } from "./upcomingProjects/addUpcomingProject";
import { getUserList } from "./user/getUserList";
import { getAdminList } from "./admin/admin";
import { getUserDetails } from "./user/getUserDetails";
import { addUserDetails } from "./user/addUserDetails";
import { updateUserDetails, updateUserImage } from "./user/updateUserDetails";
import { deleteUserDetails } from "./user/deleteUserDetails";
import { createPlan } from "./plan/createPlan";
import { getPlans } from "./plan/getPlans";
import { getdashboardStats } from "./dashboard/dashboard";
import { getPaymentList } from "./payment/getPaymentList";
import { addAppDetails } from "./appVersion/addAppDetails";
import { getPlanByID } from "./plan/getPlanDetails";
import { editPlan } from "./plan/editPlan";
import { sendNotification } from "./notification/notification.api";

export const api = {
	customer: {
		getCustomer
	},
	builder: {
		getBuilder,
		addBuilder,
		getBuilderList,
		getBuilderByID
	},
	dasboard: {
		getdashboardStats
	},
	admin: {
		createAdmin,
		adminAssignProject,
		getEmployeeList,
		getAdminList,
		getUserDetails,
		createPlan,
		getPlans,
		getPlanByID,
		editPlan
	},
	appVersion: {
		addAppDetails
	},
	auth: {
		loginAdmin,
		getOtp
	},
	user: {
		addUserDetails,
		getUserList,
		updateUserDetails,
		deleteUserDetails,
		updateUserImage
	},
	payment: {
		getPaymentList
	},
	permission: {
		getBuilderList: getBuilderList
	},
	followUp: {
		createFollowUp,
		getFollowUp
	},
	project: {
		addPlot,
		getPlots,
		getAdminPlots,
		getProjectDetails,
		getAllProjects,
		getAssignedProjectList,
		updatePlotPostion,
		getProjectList,
		addProjectFacility,
		getProjectFacilities,
		deleteProjectFacility,
		updateProject,
		getAdminAndBuilder
	},
	booking: {
		getBooking,
		forwardEnqury,
		getForwardEnquryList,
		updateEnquiryStatus,
		getBookingDetails,
		getEnquiryDetails
	},
	banner: {
		createBanner: addBanner
	},
	bank: {
		addBank,
		getBankContacts
	},
	resellerEnquiry: {
		getResellerEnquiry
	},
	upcomingProjects: {
		getUpcomingProjects,
		addUpcomingProject
	},
	notifiaction: {
		sendNotification
	}
};
