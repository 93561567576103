import { Button } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IViewPlanProps } from "../../../@types/props/PlansCellRendererProps";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";

const PlansCellRenderer = ({ data }: { data: IViewPlanProps }) => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const handleRouteToEditPlan = () => {
		console.log(data);
		navigate(`/plans/plan-details?_id=${data._id}`);
	};
	return (
		<div>
			{user?.role === ROLES.admin ? (
				<Button style={{ backgroundColor: "white", color: "lightgray" }}>No Access</Button>
			) : (
				<Button variant="outlined" className="purple-outlined-button" onClick={handleRouteToEditPlan}>
					Edit Plan Details
				</Button>
			)}
		</div>
	);
};

export default PlansCellRenderer;
