import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import Login from "./components/pages/auth/login/Login";
import { useContext } from "react";
import AuthContext from "./contexts/authContext/authContext";
import Payment from "./components/pages/payment/Payment";
import { ROLES } from "./constants/roles/Roles";
// import PhoneLogin from "./components/pages/auth/otpLogin/PhoneLogin";
// import VerifyLogin from "./components/pages/auth/otpLogin/VerifyLogin";

function App() {
	const { user } = useContext(AuthContext);
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={!user ? <Navigate to="/login" /> : <MainLayout />}>
					{routes}
				</Route>
				{/* Auth Routes */}
				<Route path="/login" element={<Login />} />
				{/* <Route path="/payment-details" element={user?.role === ROLES.admin ? null : <Payment />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
