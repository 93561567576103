import { RouteType } from "./config";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaidIcon from "@mui/icons-material/Paid";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NotificationsIcon from "@mui/icons-material/Notifications";

import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

import { Settings } from "@mui/icons-material";

import Dashboard from "../components/pages/dashboard/Dashboard";

import Profile from "../components/pages/profile/Profile";

import PersonIcon from "@mui/icons-material/Person";

import Plans from "../components/pages/plans/Plans";
import Payment from "../components/pages/payment/Payment";
import HelpIcon from "@mui/icons-material/Help";
import User from "../components/pages/user/User";
import AdminManagement from "../components/pages/adminManagement/AdminManagement";
import PermissionPage from "../components/pages/permission/PermissionPage";

import TelecallerManagement from "../components/pages/permission/telecallerManagement/TelecallerManagement";
import DashboardIndex from "../components/pages/permission/DashboardIndex";
import AddUserForm from "../components/pages/user/addUserForm/AddUserForm";
import AddPlansForm from "../components/pages/plans/addPlansForm/AddPlansForm";
import UserDetails from "../components/pages/user/userDetails/UserDetails";
import AddAdminForm1 from "../components/pages/adminManagement/AddAdminForm1";
import AppVersion from "../components/pages/appVersion/AppVersion";
import AddAppVersionForm from "../components/pages/appVersion/addAppVersionForm/AddAppVersionForm";
import PlanDetails from "../components/pages/plans/planDetails/PlanDetails";
import SendNotification from "../components/pages/sendNotification/SendNotification";

const appRoutes: RouteType[] = [
	{
		index: true,
		element: <Dashboard />,
		state: "home"
	},

	{
		path: "/dashboard",
		element: <Dashboard />,
		state: "dashboard",
		sidebarProps: {
			displayText: "Dashboard",
			icon: <DashboardIcon />
		}
	},

	{
		path: "/users",
		element: <User />,
		state: "builders",
		sidebarProps: {
			displayText: "User Details",
			icon: <PersonIcon />
		}
	},
	{
		path: "/users/user-details",
		element: <UserDetails />,
		state: "users.user-details"
	},
	{
		path: "/users/add-user",
		element: <AddUserForm />,
		state: "users.addUser"
	},

	{
		path: "/profile",
		element: <Profile />,
		state: "profile"
	},

	{
		path: "/plans",
		element: <Plans />,
		state: "plans",
		sidebarProps: {
			displayText: "Plans & Pricing",
			icon: <LocalOfferIcon />
		}
	},
	{
		path: "/send-notification",
		element: <SendNotification />,
		state: "sendNotification",
		sidebarProps: {
			displayText: "Send Notification",
			icon: <NotificationsIcon />
		}
	},
	{
		path: "/plans/add-plans",
		element: <AddPlansForm />,
		state: "plans"
	},
	{
		path: "/plans/plan-details",
		element: <PlanDetails />,
		state: "plans"
	},
	{
		path: "/payment-details",
		element: <Payment />,
		state: "plots",
		sidebarProps: {
			displayText: "Payment",
			icon: <PaidIcon />
		}
	},
	{
		path: "/enquiry-details",
		// element: <PlotsPage />,
		state: "request",
		sidebarProps: {
			displayText: "Enquiry",
			icon: <HelpIcon />
		}
	},
	{
		path: "/permission",
		element: <PermissionPage />,
		state: "permission",
		sidebarProps: {
			displayText: "Settings",
			icon: <Settings />
		},
		child: [
			{
				index: true,
				element: <DashboardIndex />,
				state: "permission"
			},
			{
				path: "/permission/admin-management",
				element: <AdminManagement />,
				state: "permission.userManagement",
				sidebarProps: {
					displayText: "Admin Management",
					icon: <SupervisedUserCircleIcon />
				}
			},
			{
				path: "/permission/app-version",
				element: <AppVersion />,
				state: "permission.appVersion",
				sidebarProps: {
					displayText: "App Version",
					icon: <SupervisedUserCircleIcon />
				}
			},
			{
				path: "/permission/add-app-version",
				element: <AddAppVersionForm />,
				state: "permission.appVersion"
			},
			{
				path: "/permission/admin-management/add-admin",
				element: <AddAdminForm1 />,
				state: "permission.adminManagement.addAdmin"
			},
			{
				path: "/permission/telecaller-management",
				element: <TelecallerManagement />,
				state: "permission.telecaller",
				sidebarProps: {
					displayText: "Telecaller Management",
					icon: <LocalPhoneIcon />
				}
			}
		]
	}
];

export default appRoutes;
