/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";

const GenderFilter = forwardRef((props: any, ref) => {
	const [selectedGender, setSelectedGender] = useState<string | null>(null);
	console.log("Props inside gender filter==>", props);
	useImperativeHandle(ref, () => ({
		isFilterActive() {
			return selectedGender !== null && selectedGender !== "";
		},
		doesFilterPass(params: any) {
			if (!selectedGender) return true;
			return params.data.gender === selectedGender;
		},
		getModel() {
			if (!this.isFilterActive()) {
				return null;
			}
			return { value: selectedGender };
		},
		setModel(model: any) {
			setSelectedGender(model ? model.value : null);
		}
	}));
	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value || null;
		setSelectedGender(value);
		props.filterChangedCallback();
	};

	return (
		<div style={{ padding: "4px" }}>
			<select onChange={handleChange} value={selectedGender || ""}>
				<option value="">All</option>
				<option value="MALE">Male</option>
				<option value="FEMALE">Female</option>
			</select>
		</div>
	);
});

export default GenderFilter;
