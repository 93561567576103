import { ColDef } from "ag-grid-community";

export const AdminManagementColDefs: ColDef[] = [
	{
		field: "full_name",
		headerName: "Full Name",
		suppressSizeToFit: true
	},
	{
		field: "email",
		headerName: "Email",
		suppressSizeToFit: true
	},
	{
		field: "phone_number",
		headerName: "Phone Number",
		suppressSizeToFit: true
	},
	// {
	// 	field: "is_disabled",
	// 	headerName: "Status",
	// 	suppressSizeToFit: true
	// },
	{
		field: "role",
		headerName: "Role",
		suppressSizeToFit: true
	}
	// {
	// 	field: "last_login_date",
	// 	headerName: "Last Login Date",
	// 	suppressSizeToFit: true
	// }
];
