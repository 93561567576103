import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import { Button, Checkbox, FormControlLabel, FormGroup, Paper, TextField } from "@mui/material";
import NotificationForm from "./notificationForm/NotificationForm";
import { api } from "../../../utils/api";

const SendNotification = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [selectedOptions, setSelectedOptions] = useState({
		male: false,
		female: false,
		all: false,
		specific: false
	});
	const [image, setImage] = useState<string | null>(null);
	const [notificationDetails, setNotificationDetails] = useState<any>({
		notification_title: "",
		notification_body: ""
	});
	const [filter, setFilter] = useState<any | null>(null);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result as string);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleNotificationDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setNotificationDetails((prevDetails: any) => ({
			...prevDetails,
			[name]: value
		}));
	};

	const handleChange = (event: any) => {
		const { name, checked } = event.target;

		if (name === "all") {
			setSelectedOptions({
				male: false,
				female: false,
				all: checked,
				specific: false
			});
			setFilter({});
		} else if (name === "male") {
			setSelectedOptions({
				male: checked,
				female: false,
				all: false,
				specific: false
			});
			setFilter({ gender: "MALE" });
		} else if (name === "female") {
			setSelectedOptions({
				male: false,
				female: checked,
				all: false,
				specific: false
			});
			setFilter({ gender: "FEMALE" });
		} else {
			setSelectedOptions((prev: any) => ({
				...prev,
				[name]: checked,
				all: false
			}));
		}
	};
	const handlePhoneNumberChange = (event: any) => {
		const { value } = event.target;
		setPhoneNumber(value);
		setFilter({ mobile: value });
	};

	const handleSentNotification = async () => {
		try {
			const formdata = new FormData();
			formdata.append("notification_title", notificationDetails.notification_title);
			formdata.append("notification_body", notificationDetails.notification_body);
			formdata.append("filter", JSON.stringify(filter));
			if (image) {
				formdata.append("image", new Blob([image], { type: "image/jpeg" }));
			}
			const response = await api.notifiaction.sendNotification(formdata);
			if (response) {
				alert("Notification sent successfully");
			}
		} catch (error) {
			alert("Error sending notification");
		}
	};

	useEffect(() => {
		setDashboardHeader("Send Notifications");
	}, [setDashboardHeader]);
	return (
		<div>
			<Paper sx={{ padding: "20px" }}>
				<div>
					<label style={{ padding: "20px", fontWeight: "semibold", fontSize: "18px", marginTop: "10px" }}>
						Send Notification to:
					</label>
					<div style={{ display: "flex", justifyContent: "start", alignItems: "start", padding: "20px" }}>
						<FormGroup sx={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
							<FormControlLabel
								control={
									<Checkbox checked={selectedOptions.male} onChange={handleChange} name="male" />
								}
								label="Male Users"
							/>
							<FormControlLabel
								control={
									<Checkbox checked={selectedOptions.female} onChange={handleChange} name="female" />
								}
								label="Female Users"
							/>
							<FormControlLabel
								control={<Checkbox checked={selectedOptions.all} onChange={handleChange} name="all" />}
								label="All Users"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedOptions.specific}
										onChange={handleChange}
										name="specific"
									/>
								}
								label="Specific User"
							/>
							{selectedOptions.specific ? (
								<>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "full",
											padding: "20px"
										}}
									>
										<label>Enter Phone Number:</label>
										<TextField
											label="Enter Phone Number"
											variant="outlined"
											value={phoneNumber}
											onChange={handlePhoneNumberChange}
											type="tel"
											sx={{ width: "400px", margin: "20px" }}
											margin="normal"
										/>
									</div>
								</>
							) : null}
						</FormGroup>
					</div>
				</div>
			</Paper>
			<NotificationForm
				key={0}
				image={image}
				handleImageUpload={handleImageUpload}
				handleNotificationDetailsChange={handleNotificationDetailsChange}
				notificationDetails={notificationDetails}
			/>
			<div style={{ display: "flex", justifyContent: "right", alignItems: "right", marginTop: "20px" }}>
				<Button variant="contained" className="button-pink" onClick={handleSentNotification}>
					Submit
				</Button>
			</div>
		</div>
	);
};

export default SendNotification;
