import { colors } from "@mui/material";

const colorConfigs = {
	sidebar: {
		bg: "#132A38",
		color: "#EFFFFF",
		hoverBg: "#255460",
		activeBg: "#3572EF",
		border: " #808080"
	},
	adminSidebar: {
		bg: "#E7F6F2",
		color: "#eeeeee",
		hoverBg: "blue",
		activeBg: "red"
	},
	topbar: {
		bg: "#EFFFFF",
		color: "#000"
	},
	mainBg: colors.grey["100"]
};

export default colorConfigs;
