import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./loginStyles.css";
import { Box } from "@mui/material";
import { SetStateAction, useContext, useState } from "react";
import AuthContext from "../../../../contexts/authContext/authContext";
import { api } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import loginImage from "../../../../assets/images/svg/Tablet login-cuate.svg";
import brandLogo from "../../../../assets/images/svg/logo3.svg";

const paperStyles = {
	width: "90%",
	height: "auto",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px"
};

export default function LoginSkeleton() {
	const { setUser } = useContext(AuthContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const handleEmailChange = (e: { target: { value: SetStateAction<string> } }) => {
		setEmail(e.target.value);
	};

	const handlePasswordChange = (e: { target: { value: SetStateAction<string> } }) => {
		setPassword(e.target.value);
	};
	const handleLogin = async () => {
		try {
			const loginPayload = { userId: email, password: password };
			const response = await api.auth.loginAdmin(JSON.stringify(loginPayload));
			if (response) {
				console.log("Responseeeee->>", response);
				// const { email: userEmail, full_name: userFullName } = response;
				setUser(response);
				console.log("Check-->", setUser);
				navigate("/");
			}
		} catch (error) {
			console.error("Error while login sending payload", error);
		}
	};
	return (
		<div className="login-page-background">
			<div className="login-image-container">
				<div
					className="login-image"
					style={{
						width: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",

						flexShrink: 0
					}}
				>
					<img src={loginImage} alt="Login Illustration" />
				</div>

				{/* Form on the right */}
				<div className="form-container">
					<Paper elevation={3} sx={paperStyles}>
						<div className="form">
							<div className="brand-logo-container">
								<img src={brandLogo} style={{ width: "50%" }} />
							</div>
							<div
								className="login_heading"
								style={{
									display: "flex",
									alignItems: "center",
									flexDirection: "column"
								}}
							>
								<h1 style={{ textAlign: "center", color: "darkslategray" }}>
									Welcome <span style={{ color: "deeppink" }}>Back!</span>
								</h1>
								<p>Login with your credentials</p>
							</div>

							<TextField
								type="text"
								label="Email"
								value={email}
								onChange={handleEmailChange}
								placeholder="Enter your email or phone number"
								sx={{ marginTop: 2, width: "100%" }}
								required
							/>
							<TextField
								label="Password"
								type="password"
								value={password}
								onChange={handlePasswordChange}
								placeholder="Enter your password"
								sx={{ marginTop: 2, width: "100%" }}
								required
							/>
							<p
								style={{
									textAlign: "right",
									marginTop: "15px",
									width: "100%"
								}}
							>
								<a style={{ textDecoration: "none" }} href="#">
									Forgot password?
								</a>
							</p>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: 2
								}}
							>
								<Button
									variant="contained"
									className="btn"
									sx={{
										marginTop: 2,
										fontSize: "16px",
										fontWeight: "bold",
										width: "100%",
										height: "50px",

										fontFamily: "Railway, sans-serif",
										background: "linear-gradient(90deg, #ff3385,#ff69b4 )",
										color: "#fff",
										"&:hover": {
											background: "linear-gradient(90deg, #ff69b4, #ff3385)" // Hover effect with gradient
										}
									}}
									onClick={handleLogin}
								>
									Next
								</Button>
							</Box>
						</div>
					</Paper>
				</div>
			</div>
		</div>
	);
}
