/* eslint-disable arrow-parens */
import React, { useState } from "react";
import { IFormProps } from "../../../@types/props/Form.props";
import { Box, MenuItem, Button, Chip, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
// interface UploadedFiles {
// 	[fieldId: string]: File | null;
// }

const Form = ({ fieldList, handleChangeText, object, errorObject }: IFormProps) => {
	const [step, setStep] = useState(0);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	// const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({});
	const fieldsPerStep = 6;
	const currentFields = fieldList.slice(step * fieldsPerStep, (step + 1) * fieldsPerStep);
	const isLastStep = (step + 1) * fieldsPerStep >= fieldList.length;
	const isFirstStep = step === 0;
	const handleNext = () => {
		if (!isLastStep) setStep(step + 1);
	};
	const handlePrevious = () => {
		if (!isFirstStep) setStep(step - 1);
	};
	const handleFocused = () => {
		setIsFocused(true);
	};
	// const handleFileChange = (fieldId: string, file: File | null) => {
	// 	handleChangeText(fieldId, file ? file.name : ""); // Use the file name or an empty string
	// 	setUploadedFiles((prev) => ({
	// 		...prev,
	// 		[fieldId]: file
	// 	}));
	// };
	return (
		<>
			<Box
				sx={{
					backgroundColor: "white",
					padding: 2,
					borderRadius: 2,
					boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
					width: "full",
					height: "full",
					margin: "auto"
				}}
			>
				{currentFields.map((field, index) => {
					return (
						<Box
							key={index}
							sx={{
								px: 4,
								py: 1
							}}
						>
							{field.type === "TEXT" && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<label htmlFor={field.id} style={{ flex: 1 }}>
										{field.label} :
									</label>
									<Box display="flex" flexDirection="column" sx={{ flex: 1 }}>
										<TextField
											id={field.id}
											type="text"
											value={object[field.id] || ""}
											placeholder={field.placeHolder}
											required={field.required}
											onChange={(e) => handleChangeText(field.id, e.target.value)}
											onFocus={handleFocused}
											inputProps={{ maxLength: field.maxLength || 250 }}
											variant="outlined"
											sx={{
												width: "100%"
											}}
										/>
										{isFocused && errorObject[field.id] && (
											<p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
												{errorObject[field.id]}
											</p>
										)}
									</Box>
								</Box>
							)}
							{field.type === "NUMBER" && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<label htmlFor={field.id} style={{ flex: 1 }}>
										{field.label} :
									</label>
									<Box display="flex" flexDirection="column" sx={{ flex: 1 }}>
										<TextField
											id={field.id}
											type="number"
											value={object[field.id] || ""}
											placeholder={field.placeHolder}
											required={field.required}
											onChange={(e) => handleChangeText(field.id, e.target.value)}
											onFocus={handleFocused}
											variant="outlined"
											sx={{
												width: "100%"
											}}
											inputProps={{
												min: 0
											}}
										/>
										{isFocused && errorObject[field.id] && (
											<p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
												{errorObject[field.id]}
											</p>
										)}
									</Box>
								</Box>
							)}
							{field.type === "PASSWORD" && (
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									sx={{ backgroundColor: "pink" }}
								>
									<label htmlFor={field.id} style={{ flex: 1 }}>
										{field.label} :
									</label>
									<Box display="flex" flexDirection="column" sx={{ flex: 1 }}>
										<TextField
											id={field.id}
											type="text"
											value={object[field.id] || ""}
											placeholder={field.placeHolder}
											required={field.required}
											onChange={(e) => handleChangeText(field.id, e.target.value)}
											onFocus={handleFocused}
											inputProps={{ maxLength: field.maxLength }}
											variant="outlined"
											sx={{
												width: "100%"
											}}
										/>
										{isFocused && errorObject[field.id] && (
											<p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
												{errorObject[field.id]}
											</p>
										)}
									</Box>
								</Box>
							)}
							{field.type === "SELECT" && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<label htmlFor={field.id}>{field.label} :</label>
									<TextField
										id={field.id}
										select
										defaultValue=""
										value={object[field.id] || ""}
										onChange={(e) => handleChangeText(field.id, e.target.value)}
									>
										{field.options?.map((option, index) => (
											<MenuItem key={index} value={option}>
												{option}
											</MenuItem>
										))}
									</TextField>
								</Box>
							)}
							{field.type === "RADIO" && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<label htmlFor={field.id}>{field.label} :</label>
									{field.options?.map((option, index) => (
										<div key={index}>
											<input
												type="radio"
												id={option}
												name={field.id}
												value={option}
												checked={object[field.id] === option}
												onChange={(e) => handleChangeText(field.id, e.target.value)}
											/>
											<label htmlFor={option}>{option}</label>
										</div>
									))}
								</Box>
							)}
							{field.type === "SWITCH" && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<label htmlFor={field.id}>{field.label} :</label>
									<Switch
										defaultChecked
										onChange={(e) => {
											const checkedValue = e.target.checked;
											handleChangeText(field.id, checkedValue ? "true" : "false");
										}}
									/>
								</Box>
							)}
							{/* {field.type === "FILE" && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<label htmlFor={field.id}>{field.label} :</label>
									<div
										style={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
											gap: "20px"
										}}
									>
										<Button variant="contained" component="label">
											Upload File
											<input
												type="file"
												hidden
												id={field.id}
												name={field.id}
												onChange={(e) =>
													handleFileChange(
														field.id,
														e.target.files ? e.target.files[0] : null
													)
												}
											/>
										</Button>
										{uploadedFiles[field.id] && (
											<Chip
												label={uploadedFiles[field.id]?.name}
												onDelete={() =>
													setUploadedFiles((prev) => ({
														...prev,
														[field.id]: null
													}))
												}
												variant="outlined"
												sx={{ marginTop: 1 }}
											/>
										)}
									</div>
								</Box>
							)} */}
						</Box>
					);
				})}

				{/* Navigation Buttons */}
				<Box display="flex" justifyContent="space-between" mt={2}>
					<Button
						variant="contained"
						onClick={handlePrevious}
						disabled={step === 0}
						sx={{ backgroundColor: "#fbcfe8", color: "white", "&:hover": { backgroundColor: "#ec4899" } }}
					>
						Previous
					</Button>
					<Button
						variant="contained"
						onClick={handleNext}
						disabled={isLastStep}
						sx={{ backgroundColor: "#fbcfe8", color: "white", "&:hover": { backgroundColor: "#ec4899" } }}
					>
						Next
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default Form;
