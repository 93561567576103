import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../../../config/colorConfigs";
import { RouteType } from "../../../../routes/config";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SidebarItem from "../sidebarItem/SidebarItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useLocation } from "react-router-dom";

type Props = {
	item: RouteType;
	id?: string;
};

const SidebarItemCollapse = ({ item, id }: Props) => {
	const [open, setOpen] = useState(false);
	const location = useLocation();

	const { appState } = useSelector((state: RootState) => state.appState);

	useEffect(() => {
		if (appState.includes(item.state)) {
			setOpen(true);
		}
	}, [appState, item]);

	return item.sidebarProps ? (
		<>
			<ListItemButton
				onClick={() => setOpen(!open)}
				sx={{
					"&: hover": {
						backgroundColor: colorConfigs.sidebar.hoverBg
					},
					paddingY: "12px",
					paddingX: "24px"
				}}
			>
				<ListItemIcon
					sx={{
						color: colorConfigs.sidebar.color
					}}
				>
					{item.sidebarProps.icon && item.sidebarProps.icon}
				</ListItemIcon>
				<ListItemText disableTypography primary={<Typography>{item.sidebarProps.displayText}</Typography>} />
				{open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
			</ListItemButton>
			<Collapse in={open} timeout="auto">
				<List>
					{item.child?.map((route, index) =>
						route.sidebarProps ? (
							route.child ? (
								<SidebarItemCollapse item={route} id={id} key={index} />
							) : location.pathname.startsWith("/admin") ? (
								<SidebarItem item={{ ...route, path: `${route.path}?cid=${id}` }} key={index} />
							) : (
								<SidebarItem item={route} key={index} />
							)
						) : null
					)}
				</List>
			</Collapse>
		</>
	) : null;
};

export default SidebarItemCollapse;
