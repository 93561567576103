import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IUserCellRendererProps } from "../../../../@types/props/UserCellRenderer.props";

const UserCellRenderer = ({ data }: { data: IUserCellRendererProps }) => {
	const navigate = useNavigate();
	const handleRouteToLeadDetails = () => {
		console.log(data._id);
		navigate(`/users/user-details?_id=${data._id}`);
	};
	return (
		<div>
			<Button variant="outlined" className="purple-outlined-button" onClick={handleRouteToLeadDetails}>
				See Details
			</Button>
		</div>
	);
};

export default UserCellRenderer;
