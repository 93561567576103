import validateEmail from "./validateEmail";
import validatePassword from "./validatePassword";

export const validateInputFields = (id: string, value: string) => {
	let error = "";
	if (id === "full_name" && !value) {
		error = "Field is required";
	} else {
		if (id === "email" && !validateEmail(value)) {
			error = "Enter a valid Email";
		}
		if (id === "password" && !validatePassword(value)) {
			error =
				"Password must be At least 8 characters long, 1 lowercase letter[a-z], 1 uppercase letter[A-Z], 1 digit[0-9], 1 special character[!@#$%^&*]";
		}
		if (id === "mobile" && value.length != 10) {
			error = "Mobile number should be 10 digits";
		}
		if (id === "no_of_brothers" && (parseFloat(value) > 10 || parseFloat(value) < 0)) {
			error = "No. of brothers should be between 0-10";
		}
		if (id === "no_of_sisters" && (parseFloat(value) > 10 || parseFloat(value) < 0)) {
			error = "No. of sisters should be between 0-10";
		}
	}
	return error;
};
