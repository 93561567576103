import { IFieldList } from "../../@types/interface/fieldList.interface";
import { districts_of_bangladesh } from "../bangladeshStateList";
import { BODY_COLOR, EYE_COLOR, HAIR_COLOR } from "../color";

export const personalDetailsFields: IFieldList[] = [
	{
		id: "full_name",
		type: "TEXT",
		label: "Full Name",
		placeHolder: "Enter your full name",
		required: true
	},
	{
		id: "email",
		type: "TEXT",
		label: "Email",
		placeHolder: "Enter your email",
		required: true
	},
	{
		id: "password",
		type: "TEXT",
		label: "Password",
		placeHolder: "Enter your password",
		required: true
	},
	{
		id: "mobile",
		type: "NUMBER",
		label: "Mobile",
		placeHolder: "Enter your mobile number",
		required: true
	},
	{
		id: "gender",
		type: "SELECT",
		label: "Gender",
		options: ["MALE", "FEMALE"]
	},
	{
		id: "body_color",
		type: "SELECT",
		label: "Body Color",
		options: BODY_COLOR
	},
	{
		id: "eye_color",
		type: "SELECT",
		label: "Eye Color",
		options: EYE_COLOR
	},
	{
		id: "hair_color",
		type: "SELECT",
		label: "Hair Color",
		options: HAIR_COLOR
	},
	// {
	// 	id: "profile_image_url",
	// 	type: "TEXT",
	// 	label: "Profile Image URL",
	// 	placeHolder: "Enter profile image URL"
	// },
	{
		id: "state",
		type: "SELECT",
		label: "State",
		options: districts_of_bangladesh
	},
	{
		id: "marital_status",
		type: "SELECT",
		label: "Marital Status",
		options: ["Single", "Married", "Divorced", "Widowed"]
	},
	{
		id: "height",
		type: "NUMBER",
		label: "Height",
		placeHolder: "Enter Height in cm"
	},
	{
		id: "weight",
		type: "NUMBER",
		label: "Weight",
		placeHolder: "Enter Weight in kg"
	}
];

export const jobFields: IFieldList[] = [
	{
		id: "occupation",
		type: "TEXT",
		label: "Occupation",
		placeHolder: "Enter occupation"
	},
	{
		id: "work_place",
		type: "TEXT",
		label: "Work Place",
		placeHolder: "Enter work place"
	},
	{
		id: "monthly_income",
		type: "NUMBER",
		label: "Monthly Income",
		placeHolder: "Enter monthly income"
	},
	{
		id: "education",
		type: "TEXT",
		label: "Education",
		placeHolder: "Enter education"
	},
	{
		id: "islamic_education",
		type: "TEXT",
		label: "Islamic Education",
		placeHolder: "Enter islamic education"
	}
];

export const religiousFields: IFieldList[] = [
	{
		id: "salah",
		type: "SWITCH",
		label: "Salah",
		placeHolder: "Enter salah"
	},
	{
		id: "sawum",
		type: "SWITCH",
		label: "Sawum",
		placeHolder: "Enter sawum"
	},
	{
		id: "hijab_maintain",
		type: "SWITCH",
		label: "Hijab Maintain",
		placeHolder: "Enter hijab maintain"
	},
	{
		id: "religious",
		type: "TEXT",
		label: "Religious",
		placeHolder: "Enter religious"
	}
];

export const familyStatusFields: IFieldList[] = [
	{
		id: "fathers_name",
		type: "TEXT",
		label: "Father's Name",
		placeHolder: "Enter father's name"
	},
	{
		id: "fathers_occupation",
		type: "TEXT",
		label: "Father's Occupation",
		placeHolder: "Enter father's occupation"
	},
	{
		id: "mothers_name",
		type: "TEXT",
		label: "Mother's Name",
		placeHolder: "Enter mother's name"
	},
	{
		id: "mothers_occupation",
		type: "TEXT",
		label: "Mother's Occupation",
		placeHolder: "Enter mother's occupation"
	},
	{
		id: "no_of_brothers",
		type: "NUMBER",
		label: "Number of Brothers",
		placeHolder: "Enter number of brothers",
		required: true
	},
	{
		id: "no_of_sisters",
		type: "NUMBER",
		label: "Number of Sisters",
		placeHolder: "Enter number of sisters",
		required: true
	},
	{
		id: "total_family_member",
		type: "NUMBER",
		label: "Total Family Members",
		placeHolder: "Enter total family members"
	},
	{
		id: "financial_condition",
		type: "TEXT",
		label: "Financial Condition",
		placeHolder: "Enter financial condition"
	}
];
