import React, { useCallback, useState } from "react";
import { IPlans } from "../../../../../@types/interface/plans.interface";
import { Box, Button, Typography } from "@mui/material";
import Form from "../../../../shared/form/Form";
import SnackbarMessage from "../../../../shared/snackbar/SnackbarMessage";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { addPlansFormFields } from "../../../../../constants/planDetailsFormFields/planDetailsFormFields";
import { api } from "../../../../../utils/api";
import { IViewPlanProps } from "../../../../../@types/props/PlansCellRendererProps";
import { useNavigate } from "react-router-dom";

const EditPlansForm = ({ plan, _id }: IViewPlanProps) => {
	const navigate = useNavigate();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const [errorObject, setErrorObject] = useState({});
	const [formObject, setFormObject] = useState<IPlans>({
		plan_name: plan?.plan_name || "",
		plan_price: plan?.plan_price || 0,
		plan_validity: plan?.plan_validity || 0,
		chat_count: plan?.chat_count || 0,
		offer_price: plan?.offer_price
	});
	const handleChangeText = (id: string, value: string) => {
		const newValue = id === "plan_name" ? value : Number(value);
		setFormObject({
			...formObject,
			[id]: newValue
		});
	};
	const handleSavePlanDetails = useCallback(
		async (event: React.FormEvent) => {
			event.preventDefault();
			try {
				//call post addPlans api here
				console.log("FormObject--->", formObject);

				const response = await api.admin.editPlan(_id, formObject);
				if (response) {
					setSnackbarOpen(true);
					setSnackbarSeverity("success");
					setSnackbarMessage("Plan details added successfully!");
				}
			} catch (error) {
				console.log("Submission error: ", error);
				setSnackbarMessage("Failed to add plan");
				setSnackbarSeverity("error");
				setSnackbarOpen(true);
			}
		},
		[formObject]
	);
	return (
		<Box component="form" onSubmit={handleSavePlanDetails}>
			<Typography variant="h4" component="h2" gutterBottom margin="20px 0 0 10px">
				Edit Plans Details
			</Typography>
			<div className="edit-btn-container">
				<Button type="submit" variant="contained" className="button-violet" endIcon={<SaveIcon />}>
					Save
				</Button>
			</div>
			<Form
				fieldList={addPlansFormFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={errorObject}
			/>
			<SnackbarMessage
				snackbarOpen={snackbarOpen}
				snackbarMessage={snackbarMessage}
				severity={snackbarSeverity}
				handleClose={() => setSnackbarOpen(false)}
			/>
		</Box>
	);
};

export default EditPlansForm;
