import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UIContext from "../../../contexts/uiContext/UIContext";
import DataGrid from "../../shared/dataGrid/DataGrid";
import { AdminManagementColDefs } from "../../../constants/adminManagement/adminManagementColDefs";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import Form from "../../shared/form/Form";
// import { IFieldList } from "../../../@types/interface/fieldList.interface";
// import AdminForm from "./AddAdminForm";
import AuthContext from "../../../contexts/authContext/authContext";
import { api } from "../../../utils/api";
import BasicPagination from "../../shared/basicPagination/BasicPagination";

const AdminManagement = () => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const { setDashboardHeader } = useContext(UIContext);
	const [adminManagemnetRowData, setAdminManagementRowData] = useState([]);
	const onFilterChange = () => {
		//onFilterChange handler here
	};
	useEffect(() => {
		setDashboardHeader("Admin Management");
		const fetchAdminList = async () => {
			try {
				const filter = { page: currentPage };
				const response = await api.admin.getAdminList(filter);

				if (response) {
					setAdminManagementRowData(response);
					console.log(response);
				}
				// if (response) {
				// 	setRowData(response.result);
				// 	const totalItems = response.pagination.total;
				// 	const calculatedTotalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
				// 	setTotalPages(calculatedTotalPages);
				// 	setCurrentPage(response.pagination.currentPage);
				// 	console.log("response", response.pagination);
				//   }
				// console.log(response);
			} catch (error) {
				console.error("Error fetching admin list:", error);
			}
		};
		fetchAdminList();
	}, [setDashboardHeader]);

	const handleAddAdminClick = () => {
		navigate("/permission/admin-management/add-admin");
	};

	return (
		<div className="">
			{user?.role === "SUPER_ADMIN" && (
				<div className="add-btn">
					<Button
						component="label"
						variant="contained"
						tabIndex={-1}
						className="button-pink"
						endIcon={<AddIcon />}
						onClick={handleAddAdminClick}
					>
						Add Admin
					</Button>
				</div>
			)}

			<DataGrid
				colDefs={AdminManagementColDefs}
				rowData={adminManagemnetRowData}
				key={0}
				onFilterChange={onFilterChange}
			/>
			{/* <BasicPagination currentPage={currentPage} handlePageChange={handlePageChange} pageCount={totalPages} /> */}
		</div>
	);
};

export default AdminManagement;
