export const ROLES = {
	admin: "ADMIN",
	super_admin: "Super Admin",
	builder_admin: "BUILDER_ADMIN",
	project_manager: "PROJECT_MANAGER",
	telecaller: "TELECALLER",
	channel_partner: "CHANNEL_PARNER",
	landlord: "LANDLORD",
	reseller: "RESELLER",
	marketer: "MARKETER"
};
