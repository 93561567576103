import { ColDef } from "ag-grid-community";

export const AppVersionColDefs: ColDef[] = [
	{
		field: "application_name",
		headerName: "App Name",
		suppressSizeToFit: true
	},
	{
		field: "application_version",
		headerName: "App Version",
		suppressSizeToFit: true
	},
	{
		field: "app_details",
		headerName: "App Details",
		suppressSizeToFit: true
	},
	{
		field: "application_size",
		headerName: "App Size",
		suppressSizeToFit: true
	},
	{
		field: "application_url",
		headerName: "App URL",
		suppressSizeToFit: true
	},
	{
		field: "is_active",
		headerName: "Active",
		suppressSizeToFit: true
	}
];
