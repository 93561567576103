import { useState } from "react";
import { IUserDetails } from "../../../@types/interface/userDetails.interface";
import { FilterModel } from "ag-grid-community";

const useFilter = () => {
	const [filters, setFilters] = useState<Partial<IUserDetails>[]>([]);
	const onFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};
	const filterReturn = { filters, onFilterChange };
	return filterReturn;
};

export default useFilter;
