import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UIContext from "../../../contexts/uiContext/UIContext";
import DataGrid from "../../shared/dataGrid/DataGrid";
import { PlansColDefs } from "../../../constants/plans/plansColDefs";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { api } from "../../../utils/api";
import BasicPagination from "../../shared/basicPagination/BasicPagination";

const Plans = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const [plansRowData, setPlansRowData] = useState([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
	};
	const onFilterChange = () => {
		//onFilterChange handler here
	};

	const getPlansDetails = useCallback(async () => {
		try {
			const payload = {};
			const result = await api.admin.getPlans(payload); // api call to get plans using filters

			setPlansRowData(result);
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	}, []);
	const handleAddPlansClick = () => {
		navigate("/plans/add-plans");
	};
	useEffect(() => {
		setDashboardHeader("Plans & Pricing");
		getPlansDetails();
	}, [setDashboardHeader, getPlansDetails, currentPage]);

	return (
		<div className="builders-container">
			<div className="add-btn">
				<Button
					component="label"
					variant="contained"
					tabIndex={-1}
					className="button-pink"
					endIcon={<AddIcon />}
					onClick={handleAddPlansClick}
				>
					Add Plans
				</Button>
			</div>
			<DataGrid colDefs={PlansColDefs} rowData={plansRowData} key={0} onFilterChange={onFilterChange} />
			<BasicPagination currentPage={currentPage} handlePageChange={handlePageChange} pageCount={totalPages} />
		</div>
	);
};

export default Plans;
