import React, { useState, useCallback } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Checkbox,
	FormControlLabel,
	Button,
	IconButton
} from "@mui/material";
import { api } from "../../../../utils/api";
import DownloadButton from "../../../shared/downloadButton/DownloadButton";
import DownloadingIcon from "@mui/icons-material/Downloading";
import CloseIcon from "@mui/icons-material/Close";
import { fieldCategories, FieldCategories } from "../../../../constants/user/userFieldCategories";

export const BulkUserDownload: React.FC<Record<string, any>> = ({ filters }) => {
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [selectedFields, setSelectedFields] = useState<string[]>([]);

	const openDialog = () => setIsDialogOpen(true);
	const closeDialog = () => setIsDialogOpen(false);

	const handleCategoryChange = (category: keyof FieldCategories) => {
		const fields = fieldCategories[category];
		setSelectedFields((prev: any) => {
			if (fields.every((field: any) => prev.includes(field))) {
				return prev.filter((field: any) => !fields.includes(field));
			} else {
				const newSelection = [...prev, ...fields];
				return Array.from(new Set(newSelection));
			}
		});
	};

	const handleDownload = async () => {
		const fieldsToSelect = selectedFields.join(" ");
		try {
			const payload = {
				...filters,
				selectField: fieldsToSelect
			};
			console.log("Formatted payload-->", payload);
			const response = await api.user.getUserList(payload);
			return response;
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	};

	return (
		<div>
			<Button variant="contained" onClick={openDialog} className="download-btn" endIcon={<DownloadingIcon />}>
				Download
			</Button>
			<Dialog open={isDialogOpen} onClose={closeDialog}>
				<DialogTitle>
					Select User Fields to Download
					<IconButton
						aria-label="close"
						onClick={closeDialog}
						sx={{ position: "absolute", right: 8, top: 8 }}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{Object.keys(fieldCategories).map((category: string) => (
						<FormControlLabel
							key={category}
							control={
								<Checkbox
									checked={fieldCategories[category as keyof FieldCategories].every((field: string) =>
										selectedFields.includes(field)
									)}
									onChange={() => handleCategoryChange(category as keyof FieldCategories)}
								/>
							}
							label={category
								.replace(/([A-Z])/g, " $1")
								.replace(/^./, (str: string) => str.toUpperCase())}
						/>
					))}
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>Cancel</Button>
					<DownloadButton apiCall={handleDownload} fileName="All Users List" key={"users"} />
				</DialogActions>
			</Dialog>
		</div>
	);
};
