import { Button } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";

import { api } from "../../../utils/api";
import DataGrid from "../../shared/dataGrid/DataGrid";

import BasicPagination from "../../shared/basicPagination/BasicPagination";
import AddIcon from "@mui/icons-material/Add";
import { UserColDefs } from "../../../constants/user/userColDefs";
import { BulkUserDownload } from "./bulkUserDownload/BulkUserDownload";

//hooks
import useGetUserList from "../../../utils/hooks/userList/useGetUserList";
import useDataPagination from "../../../utils/hooks/userList/useDataPagination";
import useRowSelection from "../../../utils/hooks/rowSelection/useRowSelection";
import useFilter from "../../../utils/hooks/filter/useFilter";
import useDashboardHeader from "../../../utils/hooks/dashboardHeader/useDashboardHeader";
import useEditMessageLimit from "../../../utils/hooks/editMessageLimit/useEditMessageLimit";

const User = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const page = parseInt(queryParams.get("page") || "1", 10);

	const { filters, onFilterChange } = useFilter();
	const { userDataPagination, handlePageChange, handleSetPagination } = useDataPagination({ page });
	const rowData = useGetUserList({ filter: filters, userDataPagination, handleSetPagination });
	const { selectedRowId, handleRowSelect } = useRowSelection();
	const { editMessageLimit } = useEditMessageLimit();
	useDashboardHeader("Users");

	const handleAddUserClick = () => {
		navigate("/users/add-user");
	};

	return (
		<div className="builders-container">
			<div className="add-btn">
				<BulkUserDownload filters={filters} />
				<Button
					component="label"
					variant="contained"
					tabIndex={-1}
					className="button-pink"
					endIcon={<AddIcon />}
					onClick={handleAddUserClick}
				>
					Add User
				</Button>
			</div>
			<DataGrid
				colDefs={UserColDefs}
				rowData={rowData}
				key={0}
				onFilterChange={onFilterChange}
				handleCellValueChange={editMessageLimit}
				selectedRowId={selectedRowId}
				onRowSelected={handleRowSelect}
			/>
			<BasicPagination
				currentPage={userDataPagination.currentPage}
				handlePageChange={handlePageChange}
				pageCount={userDataPagination.pageCount}
			/>
		</div>
	);
};

export default User;
