import React, { useState } from "react";
import { Box, Toolbar } from "@mui/material";

import sizeConfigs from "../../config/sizeConfigs";
import Sidebar from "../shared/sidebar/Sidebar";
import Topbar from "./topbar/Topbar";
import { Outlet, useLocation } from "react-router-dom";

const MainLayout = () => {
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const sidebarWidth = sizeConfigs.sidebar.width;
	const location = useLocation();

	const isOnAdminPage = location.pathname.startsWith("/admin");
	const isOnOwnerPage = location.pathname.startsWith("/landlord");
	const isOnRetailerPage = location.pathname.startsWith("/reseller");
	const isOnMarketerPage = location.pathname.startsWith("/marketer");

	const renderSidebar = () => {
		if (isOnAdminPage) {
			// return <AdminSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
		} else if (isOnOwnerPage) {
			// return <OwnerSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
		} else if (isOnRetailerPage) {
			// return <ResellerSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
		} else if (isOnMarketerPage) {
			// return <MarketerSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
		} else {
			return <Sidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
		}
	};

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

	return (
		<div style={{ display: "flex" }}>
			<Topbar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
			<Box
				component="nav"
				sx={{
					width: sidebarOpen ? sidebarWidth : 0,
					flexShrink: 0,
					transition: "width 0.2s ease-in-out"
				}}
			>
				{renderSidebar()}
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: sidebarOpen ? `calc(100% - ${sidebarWidth}px)` : "100%",
					minHeight: "100vh",
					transition: "width 0.2s ease-in-out"
				}}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</div>
	);
};

export default MainLayout;
