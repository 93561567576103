import { useContext, useEffect } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";

const useDashboardHeader = (headerName: string) => {
	const { setDashboardHeader, dashboardHeader } = useContext(UIContext);
	useEffect(() => {
		setDashboardHeader(headerName);
	}, [headerName, setDashboardHeader]);
	return dashboardHeader;
};

export default useDashboardHeader;
