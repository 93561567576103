/* eslint-disable react/prop-types */
import { Snackbar, Alert } from "@mui/material";

interface SnackbarMessageProps {
	snackbarOpen: boolean;
	snackbarMessage: string;
	severity?: "success" | "error" | "warning" | "info";
	handleClose: () => void;
}

const SnackbarMessage: React.FC<SnackbarMessageProps> = ({
	snackbarOpen,
	snackbarMessage,
	severity = "success",
	handleClose
}) => {
	return (
		<Snackbar
			open={snackbarOpen}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
				{snackbarMessage}
			</Alert>
		</Snackbar>
	);
};

export default SnackbarMessage;
