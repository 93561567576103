import React, { useCallback, useContext, useEffect, useState } from "react";

import UIContext from "../../../../contexts/uiContext/UIContext";
import { api } from "../../../../utils/api";
import EditPlansForm from "./editPlansForm/EditPlansForm";
import { IPlans } from "../../../../@types/interface/plans.interface";

const PlanDetails = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const [plan, setPlan] = useState<IPlans | null>(null);
	const queryParams = new URLSearchParams(window.location.search);
	const planId = queryParams.get("_id") || "";

	const getPlanDetails = useCallback(async () => {
		//api call to get payrollDetails
		try {
			const planDetails = await api.admin.getPlanByID(planId);
			if (planDetails) {
				setPlan(planDetails);
			}
		} catch (error) {
			console.log("Error-->", error);
		}
	}, [planId]);
	useEffect(() => {
		setDashboardHeader("Plan Details");
		getPlanDetails();
	}, [setDashboardHeader, getPlanDetails]);
	return <>{plan && <EditPlansForm plan={plan} _id={planId} />}</>;
};

export default PlanDetails;
