import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import DataGrid from "../../shared/dataGrid/DataGrid";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import { AppVersionColDefs } from "../../../constants/appVersion/appVersionColDefs";
import { useNavigate } from "react-router-dom";
const AppVersion = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
	};
	const onFilterChange = () => {
		//onFilterChange handler here
	};
	const handleAddAppVersionClick = (e: React.MouseEvent) => {
		navigate("/permission/add-app-version");
	};
	useEffect(() => {
		setDashboardHeader("App version");
	}, [setDashboardHeader]);
	return (
		<div>
			<div className="add-btn">
				<Button
					component="label"
					variant="contained"
					tabIndex={-1}
					className="button-pink"
					endIcon={<AddIcon />}
					onClick={handleAddAppVersionClick}
				>
					Add App Apk
				</Button>
			</div>
			<DataGrid colDefs={AppVersionColDefs} rowData={[]} key={0} onFilterChange={onFilterChange} />
			<BasicPagination currentPage={currentPage} handlePageChange={handlePageChange} pageCount={totalPages} />
		</div>
	);
};

export default AppVersion;
