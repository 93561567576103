import { Button, CircularProgress } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useState } from "react";
import { downloadExcel } from "../../../utils/commonFunctions/downloadExcel";

const DownloadButton = ({ apiCall, fileName }: { apiCall: any; fileName: string }) => {
	const [loading, setLoading] = useState<boolean>(false);

	const handleDownload = async () => {
		try {
			setLoading(true);
			const response = await apiCall();
			if (response) {
				downloadExcel(response.result, `${fileName}.xlsx`);
			}
		} catch (error) {
			alert(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Button variant="contained" onClick={handleDownload} className="download-btn" endIcon={<DownloadingIcon />}>
			{loading ? <CircularProgress size={20} /> : "Download"}
		</Button>
	);
};

export default DownloadButton;
