import { Box, Button, Chip, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { addAppDetailsFormFields } from "../../../../constants/appVersion/addAppDetailsFormFields";
import Form from "../../../shared/form/Form";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { defaultAddAppFormObject } from "../../../../constants/appVersion/defaultAddAppFormObject";
import { IApp } from "../../../../@types/interface/appVersion.interface";
import SnackbarMessage from "../../../shared/snackbar/SnackbarMessage";
import { api } from "../../../../utils/api";
const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1
});
const AddAppVersionForm = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const [formObject, setFormObject] = useState<IApp>(defaultAddAppFormObject);
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const handleChangeText = (id: string, value: string) => {
		setFormObject({
			...formObject,
			[id]: value
		});
	};
	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		console.log(formObject);
		try {
			const formData = new FormData();
			formData.append("payload", JSON.stringify(formObject));
			if (uploadedFile) {
				formData.append("application", uploadedFile);
			}
			const response = await api.appVersion.addAppDetails(formData); // post api call

			if (response) {
				setSnackbarMessage("App details added successfully!");
				setSnackbarSeverity("success");
				setSnackbarOpen(true);
				setFormObject(defaultAddAppFormObject);
				setUploadedFile(null);
			}
		} catch (error) {
			// Handle error (you might want to show an error snackbar here)
			setSnackbarMessage("Failed to add App details!");
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
			console.error("Submission error: ", error);
		}
	};
	useEffect(() => {
		setDashboardHeader("App version");
	}, [setDashboardHeader]);
	return (
		<Box component="form" onSubmit={handleFormSubmit}>
			<h2>Add App version</h2>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "end",
					alignItems: "center",
					gap: "20px",
					right: "0"
				}}
			>
				<Button
					style={{
						marginTop: 0,
						marginBottom: 10,
						backgroundColor: "#ec4899"
					}}
					component="label"
					role={undefined}
					variant="contained"
					tabIndex={-1}
					startIcon={<CloudUploadIcon />}
				>
					Upload App Apk
					<VisuallyHiddenInput
						type="file"
						onChange={(event: any) => {
							const file = event.target.files?.[0];
							if (file) {
								setUploadedFile(file);
							}
						}}
						required
					/>
				</Button>

				{uploadedFile && (
					<Chip
						label={uploadedFile.name}
						onDelete={() => setUploadedFile(null)}
						variant="outlined"
						sx={{ marginTop: 1 }}
					/>
				)}
			</div>
			<Form
				fieldList={addAppDetailsFormFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={{}}
			/>
			<div className="add-btn">
				<Button
					variant="contained"
					tabIndex={-1}
					className="button-pink"
					type="submit"
					endIcon={<SendIcon />}
					sx={{ mt: 2 }}
				>
					Submit
				</Button>
			</div>
			<SnackbarMessage
				snackbarOpen={snackbarOpen}
				snackbarMessage={snackbarMessage}
				severity={snackbarSeverity}
				handleClose={() => setSnackbarOpen(false)}
			/>
		</Box>
	);
};

export default AddAppVersionForm;
