import React, { useState } from "react";
import { IPagination } from "../../../@types/interface/pagination";
import { useNavigate } from "react-router-dom";

const useDataPagination = ({ page }: { page: number }) => {
	const navigate = useNavigate();
	const [userDataPagination, setUserDataPagination] = useState<IPagination>({
		currentPage: page,
		pageCount: 1
	});
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setUserDataPagination({
			...userDataPagination,
			currentPage: value
		});
		navigate(`?page=${value}`);
	};

	const handleSetPagination = (pagination: IPagination) => {
		setUserDataPagination(pagination);
	};

	const pagination = {
		userDataPagination,
		handlePageChange,
		handleSetPagination
	};

	return pagination;
};

export default useDataPagination;
