import UIContext from "../../../../contexts/uiContext/UIContext";
import { useContext, useEffect } from "react";

const TelecallerManagement = () => {
	const { setDashboardHeader } = useContext(UIContext);

	useEffect(() => {
		setDashboardHeader("Telecaller Management");
	});
	return (
		<div>
			<h3>Telecaller Management</h3>
		</div>
	);
};

export default TelecallerManagement;
