import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";

import { Box, Card, Paper, Switch, Theme, createStyles, makeStyles } from "@mui/material";
import { BarChart, DashboardCard, DonutChart, LineChart, Map, PieChart } from "../../shared";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import LandscapeIcon from "@mui/icons-material/Landscape";
import PeopleIcon from "@mui/icons-material/People";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { red } from "@mui/material/colors";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import "./dashboard.css";
import UIContext from "../../../contexts/uiContext/UIContext";
import { api } from "../../../utils/api";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";

const Dashboard = () => {
	const { user } = useContext(AuthContext);
	const [switchValue, setSwitchValue] = useState<boolean>(false);
	const [dashboardData, setDashboardData] = useState({
		newRegistrations: 0,
		totalUsers: 0,
		totalMaleUsers: 0,
		totalFemaleUsers: 0,
		todayMaleUsers: 0,
		todayFemaleUsers: 0,
		verifiedUsers: 0,
		unverifiedUsers: 0,
		totalRevenue: 0,
		planSoldData: []
	});

	const { setDashboardHeader } = useContext(UIContext);
	const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSwitchValue(event.target.checked);
	};
	const UserData = [
		{
			id: "Male",
			label: "Male Users",
			value: dashboardData.totalMaleUsers,
			color: "#4A90E2"
		},
		{
			id: "Female",
			label: "Female Users",
			value: dashboardData.totalFemaleUsers,
			color: "#FF6F91"
		}
	];

	const barChart = [
		{
			day: "1/10/2024",
			value: 190,
			color: "hsl(326, 70%, 50%)"
		},
		{
			day: "2/10/2024",
			value: 141,
			color: "hsl(132, 70%, 50%)"
		},
		{
			day: "3/10/2024",
			value: 56,
			color: "hsl(227, 70%, 50%)"
		},
		{
			day: "4/10/2024",
			value: 191,
			color: "hsl(286, 70%, 50%)"
		},
		{
			day: "5/10/2024",
			value: 163,
			color: "hsl(227, 70%, 50%)"
		},
		{
			day: "6/10/2024",
			value: 120,
			color: "blue"
		},
		{
			day: "7/10/2024",
			value: 40,
			color: "red"
		}
	];
	const getDashboardData = useCallback(async () => {
		try {
			const payload = {};
			const result = await api.dasboard.getdashboardStats(payload);
			if (result) {
				console.log("dashboardData==>", result);
				setDashboardData(result);
			}
		} catch (error) {
			console.log("Error");
		}
	}, []);
	useEffect(() => {
		setDashboardHeader("Dashboard");
		getDashboardData();
	}, [setDashboardHeader]);
	return (
		<div>
			<div className="home-page">
				<div className="cards-section">
					{/* {user?.role === ROLES.admin ? null : (
						<Paper className="revenue-card">
							<h2 style={{ color: "#B961F8", textAlign: "center" }}>৳{dashboardData.totalRevenue}</h2>
							<div className="text">
								<p>Total Revenue Earned</p>
								<span>
									<AttachMoneyOutlinedIcon />
								</span>
							</div>
						</Paper>
					)} */}
					<Paper className="leads-card">
						<h2 style={{ color: "#0BC34F", textAlign: "center" }}>{dashboardData.newRegistrations}</h2>
						<div className="text">
							<p>Today&#39;s New Registrations</p>
							<span>
								<PersonAddIcon />
							</span>
						</div>
					</Paper>
					<Paper className="customer-card">
						<h2 style={{ color: "#0F9BBE", textAlign: "center" }}>{dashboardData?.todayMaleUsers || 0}</h2>
						<div className="text">
							<p>Todays Male User</p>
							<span>
								<ManIcon />
							</span>
						</div>
					</Paper>
					<Paper className="request-card">
						<h2 style={{ color: "#F98F1E", textAlign: "center" }}>
							{dashboardData?.todayFemaleUsers || 0}
						</h2>
						<div className="text">
							<p>Todays Female User</p>
							<span>
								<WomanIcon />
							</span>
						</div>
					</Paper>
					<Paper className="customer-card">
						<h2 style={{ color: "#0F9BBE", textAlign: "center" }}>{dashboardData?.verifiedUsers || 0}</h2>
						<div className="text">
							<p>Total verified User</p>
							<span>
								<ManIcon />
							</span>
						</div>
					</Paper>
					<Paper className="total-users-card">
						<h2 style={{ color: "#6900cc", textAlign: "center" }}>{dashboardData.totalUsers}</h2>
						<div className="text">
							<p>Total Users</p>
							<span>
								<ManIcon />
							</span>
						</div>
					</Paper>
					<Paper className="customer-card">
						<h2 style={{ color: "#0F9BBE", textAlign: "center" }}>{dashboardData.totalMaleUsers}</h2>
						<div className="text">
							<p>Total Male Users</p>
							<span>
								<ManIcon />
							</span>
						</div>
					</Paper>
					<Paper className="request-card">
						<h2 style={{ color: "#F98F1E", textAlign: "center" }}>{dashboardData.totalFemaleUsers}</h2>
						<div className="text">
							<p>Total Female Users</p>
							<span>
								<WomanIcon />
							</span>
						</div>
					</Paper>
					<Paper className="customer-card">
						<h2 style={{ color: "#0F9BBE", textAlign: "center" }}>{dashboardData?.unverifiedUsers || 0}</h2>
						<div className="text">
							<p>Total Unverified User</p>
							<span>
								<ManIcon />
							</span>
						</div>
					</Paper>
				</div>
				<div className="mid-section">
					<div className="bigchart-container">
						<div className="top-section">
							<h2 style={{ textAlign: "center" }}>Plan Sales Chart</h2>
						</div>
						<div className="bottom-section">
							<PieChart
								pieChartData={dashboardData?.planSoldData ? dashboardData.planSoldData : UserData}
							/>
						</div>
					</div>
					<div className="two-charts-container">
						<div className="bottom-chart">
							<div className="top-section">
								<h2>Registration Trend</h2>
							</div>
							<div className="bottom-section">
								<BarChart barChartData={barChart} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
