import { ColDef } from "ag-grid-community";

export const PaymentColDefs: ColDef[] = [
	{
		field: "tran_id",
		headerName: "Transaction ID",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "user_details.full_name",
		headerName: "Customer Name",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "user_details.mobile",
		headerName: "Phone No.",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "user_details.gender",
		headerName: "Gender",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "user_details.state",
		headerName: "State",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "plan_name",
		headerName: "Plan Name",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "price",
		headerName: "Plan Price",
		suppressSizeToFit: true,
		flex: 1
	}
];
