export const url = "https://d3be5gm8hnmvue.cloudfront.net";
export const AUTH_URL = "https://j4d9fb5o15.execute-api.ap-south-1.amazonaws.com";
export const BASE_URL = "https://d3be5gm8hnmvue.cloudfront.net";
export const port = "8989";
export const version = "v1";

export const headers = {
	Accept: "application/json",
	"Content-Type": "application/json"
};
