import { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { DataGridProp } from "../../../@types/props/DataGrid.props";
import "./datagrid.css";

interface IRow {
	make: string;
	model: string;
	price: number;
}

const DataGrid = ({
	rowData,
	colDefs,
	onFilterChange,
	pinnedBottomRowData,
	handleCellValueChange,
	selectedRowId,
	onRowSelected
}: DataGridProp) => {
	const gridApi = useRef<any>(null);
	const defaultColDef = useMemo(
		() => ({
			filterParams: {
				debounceMs: 500
			}
		}),
		[]
	);
	const handleCellValueChanged = useCallback((params: any) => {
		const { data, oldValue, newValue, colDef } = params;

		if (oldValue !== newValue) {
			const updateField = colDef.field;
			const payload = {
				userDetails: {
					[updateField]: newValue
				},
				userObjectId: data._id
			};
			if (handleCellValueChange) {
				handleCellValueChange(payload);
			}
		}
	}, []);

	const onFilterChanged = useCallback(() => {
		if (gridApi.current) {
			const filterModel = gridApi.current.getFilterModel();
			if (filterModel.mobile && !/^\d+$/.test(filterModel.mobile.filter)) {
				alert("Mobile number can contain numeric characters only).");
				delete filterModel.mobile;
				gridApi.current.setFilterModel(filterModel);
			}
			onFilterChange(filterModel);
		}
	}, [onFilterChange]);

	useEffect(() => {
		if (gridApi.current && selectedRowId) {
			gridApi.current.forEachNode((node: any) => {
				node.setSelected(node.data && node.data._id === selectedRowId);
			});
		}
	}, [selectedRowId]);

	const handleRowClicked = useCallback(
		(params: any) => {
			if (onRowSelected && params.data && params.data._id) {
				onRowSelected(params.data._id);
			}
		},
		[onRowSelected]
	);
	return (
		<div className="ag-theme-alpine" style={{ height: 450 }}>
			<AgGridReact
				onGridReady={(params: any) => {
					gridApi.current = params.api;
				}}
				animateRows={true}
				rowSelection="single"
				rowData={rowData}
				columnDefs={colDefs}
				defaultColDef={defaultColDef}
				onFilterChanged={onFilterChanged}
				pinnedBottomRowData={pinnedBottomRowData || []}
				onCellValueChanged={handleCellValueChanged}
				onRowClicked={handleRowClicked}
				rowClassRules={{
					"highlight-row": (params: any) => params.data && params.data._id === selectedRowId
				}}
			/>
		</div>
	);
};

export default DataGrid;
