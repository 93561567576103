import { useCallback, useEffect, useState } from "react";

const useRowSelection = () => {
	const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
	const handleRowSelect = (rowId: string) => {
		const data = {
			rowId,
			timestamp: Date.now()
		};
		sessionStorage.setItem("selectedRow", JSON.stringify(data));
	};

	const restoreSelectedRow = useCallback(() => {
		const savedData = sessionStorage.getItem("selectedRow");
		if (savedData) {
			const { rowId, timestamp } = JSON.parse(savedData);
			if (Date.now() - timestamp < 10 * 60 * 1000) {
				setSelectedRowId(rowId);
			} else {
				sessionStorage.removeItem("selectedRow");
			}
		}
	}, []);

	useEffect(() => {
		restoreSelectedRow();
	}, [restoreSelectedRow]);

	const row = {
		selectedRowId,
		handleRowSelect
	};
	return row;
};

export default useRowSelection;
