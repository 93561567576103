import { Outlet } from "react-router-dom";

const PermissionPage = () => {
	return (
		<div>
			<Outlet />
		</div>
	);
};

export default PermissionPage;
