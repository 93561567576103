import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { Payload } from "../../../@types/api/api.types";

const { patch } = request;

const initialRoute = "plans";
export const editPlan = async (plan_id: string, payload: Payload) => {
	try {
		if (!plan_id) {
			throw new Error("Plan ID is required.");
		}
		const endpoint = `${initialRoute}/edit-plan/${plan_id}`;
		const response = await patch(endpoint, payload, {
			...headers
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.patch.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error("Failed to add plan details.");
	} catch (error) {
		console.log(error);
		throw error;
	}
};
