import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { Payload } from "../../../@types/api/api.types";

const { post } = request;

const initialRoute = "android-app";

export const addAppDetails = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/upload-app`;

		const response = await post(
			endpoint,
			payload,
			{
				...headers,
				"Content-Type": "multipart/form-data"
			},
			"BASE"
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error) {
		console.log("Failed to add app details");
		throw error;
	}
};
