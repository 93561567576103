import { IUserDetails } from "../../@types/interface/userDetails.interface";

export const userFormObject: Partial<IUserDetails> = {
	full_name: "",
	email: "",
	password: "",
	mobile: "",
	gender: "MALE",
	body_color: "Brown",
	eye_color: "Black",
	hair_color: "Black",
	state: "Bagerhat",
	marital_status: "Single",
	height: 0,
	weight: 0,
	// profile_image_url: "",
	occupation: "",
	work_place: "",
	monthly_income: "",
	education: "",
	islamic_education: "",
	salah: "true",
	sawum: "true",
	hijab_maintain: "true",
	religious: "",
	fathers_name: "",
	fathers_occupation: "",
	mothers_name: "",
	mothers_occupation: "",
	no_of_brothers: 0,
	no_of_sisters: 0,
	total_family_member: 0,
	financial_condition: ""
};
