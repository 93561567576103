import { ColDef } from "ag-grid-community";
import UserCellRenderer from "../../components/pages/user/userCellRenderer/UserCellRenderer";
import DeleteUserRenderer from "../../components/pages/user/userCellRenderer/UserDeleteCellRenderer";
import VerifyUserCellRenderer from "../../components/pages/user/userCellRenderer/UserVerifyCellRenderer";
import ProfileCompleteIndicator from "../../components/pages/user/userCellRenderer/ProfileCompleteIndicator";
import FormattedDateCellRenderer from "./formattedDateCellRenderer/FormattedDateCellRenderer";
import NameCellRenderer from "../../components/pages/user/nameCellRenderer/NameCellRenderer";
import PhoneCellRenderer from "./phoneCellRenderer/PhoneCellRenderer";
import GenderFilter from "../../components/pages/user/userCellRenderer/GenderFilter";
import WhatsappCellRenderer from "./whatsappCellRenderer/WhatsappCellRenderer";

// const {user} = useContext(AuthContext);

export const UserColDefs: ColDef[] = [
	{
		field: "full_name",
		headerName: "Name",
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		cellRenderer: NameCellRenderer
	},
	{
		field: "profileCompleteness",
		headerName: "Profile Complete(%)",
		width: 90,
		cellRenderer: ProfileCompleteIndicator,
		filter: "agTextColumnFilter",
		suppressSizeToFit: true,
		floatingFilter: true
	},

	{
		field: "mobile",
		headerName: "Mobile No.",
		width: 170,
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		cellRenderer: PhoneCellRenderer
	},
	{
		field: "mobile",
		headerName: "Message on whatsapp",
		width: 120,
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		cellRenderer: WhatsappCellRenderer
	},
	{
		field: "gender",
		headerName: "Gender",
		width: 90,
		suppressSizeToFit: true,
		filter: GenderFilter, //pending task - properly implement gender filter
		floatingFilter: true
	},
	{
		field: "age",
		headerName: "Age",
		width: 90,
		suppressSizeToFit: true,
		filter: "agNumberColumnFilter",
		floatingFilter: true
	},
	{
		field: "state",
		headerName: "State",
		width: 90,
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true
	},
	{
		field: "occupation",
		headerName: "Job",
		width: 90,
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true
	},
	{
		field: "message_limit",
		headerName: "Message Limit",
		width: 90,
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		editable: true
	},
	{
		field: "updatedAt",
		headerName: "Last Login Date",
		suppressSizeToFit: true,
		width: 120,
		editable: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		cellRenderer: FormattedDateCellRenderer
	},
	{
		field: "createdAt",
		headerName: "Created At",
		suppressSizeToFit: true,
		width: 120,
		editable: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		cellRenderer: FormattedDateCellRenderer
	},
	{
		field: "verify",
		headerName: "Verify User",
		width: 90,
		cellRenderer: VerifyUserCellRenderer
	},
	{
		field: "action",
		headerName: "Action",
		width: 120,
		cellRenderer: UserCellRenderer
	},

	{
		field: "delete",
		headerName: "Delete",
		width: 120,
		cellRenderer: DeleteUserRenderer
	}
];

// if (user?.role !== 'admin') {
// 	UserColDefs.push({
// 	  headerName: "Delete",
// 	  field: "delete",
// 	 cellRenderer: DeleteUserRenderer
//   })}
