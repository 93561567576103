/* eslint-disable arrow-parens */
import { Box, Button, TextField, Snackbar, Alert, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { api } from "../../../utils/api";

const AddAdminForm1 = () => {
	const [adminDetails, setAdminDetails] = useState({
		full_name: "",
		email: "",
		password: "",
		role: "",
		phone_number: ""
	});

	const [successMessage, setSuccessMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const handleChange = (e: { target: { name: any; value: any } }) => {
		const { name, value } = e.target;
		setAdminDetails((prevDetails) => ({
			...prevDetails,
			[name]: value
		}));
	};

	const handleAdminDetailsSubmit = async () => {
		try {
			const response = await api.admin.createAdmin(adminDetails);
			// console.log("Checking response--->", response);
			if (response) {
				setAdminDetails({
					full_name: "",
					email: "",
					password: "",
					role: "",
					phone_number: ""
				});

				// Show success message
				setSuccessMessage(true);
			} else {
				setErrorMessage(true);
			}
		} catch (error) {
			console.error("Error submitting admin details: ", error);
			setErrorMessage(true);
		}
	};

	return (
		<div>
			<Box>
				<h2>Create Admin</h2>
				<Box
					sx={{
						backgroundColor: "white",
						padding: 2,
						borderRadius: 2,
						boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
						width: "full",
						height: "full",
						margin: "auto"
					}}
				>
					{/* Full Name */}
					<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
						<label>Full Name</label>
						<TextField
							placeholder="Full Name"
							name="full_name"
							value={adminDetails.full_name}
							onChange={handleChange}
							variant="outlined"
							sx={{
								width: "50%"
							}}
						/>
					</Box>

					{/* Email */}
					<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
						<label>Email</label>
						<TextField
							placeholder="Email"
							name="email"
							value={adminDetails.email}
							onChange={handleChange}
							type="email"
							variant="outlined"
							sx={{
								width: "50%"
							}}
						/>
					</Box>

					{/* Password */}
					<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
						<label>Password</label>
						<TextField
							placeholder="Password"
							name="password"
							onChange={handleChange}
							value={adminDetails.password}
							type="password"
							variant="outlined"
							sx={{
								width: "50%"
							}}
						/>
					</Box>

					{/* Role */}
					<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
						<label>Role</label>
						<FormControl sx={{ width: "50%" }}>
							<InputLabel id="demo-simple-select-label">Select Role</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={adminDetails.role}
								label="Select Role"
								name="role"
								onChange={handleChange}
							>
								<MenuItem value={"SUPER_ADMIN"}>Super Admin</MenuItem>
								<MenuItem value={"ADMIN"}>Admin</MenuItem>
								<MenuItem value={"TELECALLER"}>Telecaller</MenuItem>
							</Select>
						</FormControl>
					</Box>

					{/* Phone Number */}
					<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
						<label>Phone Number</label>
						<TextField
							placeholder="Phone Number"
							name="phone_number"
							variant="outlined"
							value={adminDetails.phone_number}
							onChange={handleChange}
							sx={{
								width: "50%"
							}}
						/>
					</Box>
				</Box>

				{/* Submit Button */}
				<div className="add-btn">
					<Button
						component="label"
						variant="contained"
						tabIndex={-1}
						className="button-pink"
						endIcon={<SendIcon />}
						onClick={handleAdminDetailsSubmit}
						sx={{
							mt: 2
						}}
					>
						Submit
					</Button>
				</div>
			</Box>

			{/* Success Message */}
			<Snackbar
				open={successMessage}
				autoHideDuration={6000}
				onClose={() => setSuccessMessage(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={() => setSuccessMessage(false)} severity="success" sx={{ width: "100%" }}>
					Admin created successfully!
				</Alert>
			</Snackbar>

			{/* Error Message */}
			<Snackbar
				open={errorMessage}
				autoHideDuration={6000}
				onClose={() => setErrorMessage(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={() => setErrorMessage(false)} severity="error" sx={{ width: "100%" }}>
					Failed to create admin. Please try again!
				</Alert>
			</Snackbar>
		</div>
	);
};

export default AddAdminForm1;
