import { IconButton } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { IUserDetails } from "../../../@types/interface/userDetails.interface";

const WhatsappCellRenderer = ({ data }: { data: IUserDetails }) => {
	const message = `Hello! ${data.full_name}`;

	const openWhatsApp = () => {
		const formattedNumber = `+880${data.mobile}`;
		// console.log(formattedNumber)
		const url = `https://wa.me/${formattedNumber}?text=${encodeURIComponent(message)}`;
		window.open(url, "_blank");
	};

	return (
		<div>
			<IconButton onClick={openWhatsApp}>
				<WhatsApp fontSize="small" sx={{ color: "green" }} />
			</IconButton>
		</div>
	);
};

export default WhatsappCellRenderer;
