import { Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { request } from "../api";

const { post } = request;
const initialRoute = "plans";

export const createPlan = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/create-plan`;
		const response = await post(endpoint, payload, {
			...headers
		});
		if (response) {
			const { message, result } = response.data;

			if (message === MESSAGE.post.succ) {
				return result; // Return the result
			} else {
				throw new Error("Error creating plan");
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while plan create api call:", error);
		throw error;
	}
};
