import { IFieldList } from "../../@types/interface/fieldList.interface";

export const addPlansFormFields: IFieldList[] = [
	{
		id: "plan_name",
		type: "TEXT",
		label: "Plan Name",
		placeHolder: "Enter Plan Name",
		required: true
	},
	{
		id: "plan_price",
		type: "NUMBER",
		label: "Plan Price",
		placeHolder: "Enter Plan Price",
		required: true
	},
	{
		id: "offer_price",
		type: "NUMBER",
		label: "Plan Offer Price",
		placeHolder: "Enter Plan Offer Price"
	},
	{
		id: "plan_validity",
		type: "NUMBER",
		label: "Plan Validity in Days",
		placeHolder: "Enter Plan Validity",
		required: true
	},
	{
		id: "chat_count",
		type: "NUMBER",
		label: "Chat count",
		placeHolder: "Enter Chat Count",
		required: true
	}
];
