import { Button, Switch } from "@mui/material";
import React, { useCallback, useState } from "react";
import { api } from "../../../../utils/api";
import { IUserDetails } from "../../../../@types/interface/userDetails.interface";
import UserDetails from "../userDetails/UserDetails";

const VerifyUserCellRenderer = ({ data }: { data: IUserDetails }) => {
	const [isVerified, setIsVerified] = useState(data.is_verified);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		setIsVerified(checked);
		handleVerifyUser(checked);
	};
	const handleVerifyUser = useCallback(
		async (checked: boolean) => {
			// verify user backend call
			try {
				const payload = {
					userDetails: {
						is_verified: checked
					},
					userObjectId: data._id
				};
				const result = await api.user.updateUserDetails(payload);
				if (result) {
					alert("User Verified Successfully!");
				}
			} catch (error) {
				console.log("Error", error);
			}
		},
		[data._id]
	);
	return (
		<div>
			<Switch checked={isVerified} onChange={handleChange} color="primary" />
		</div>
	);
};

export default VerifyUserCellRenderer;
