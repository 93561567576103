import React, { useCallback, useState } from "react";
import { Paper, TextField, Button, Grid, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { IViewUserProps } from "../../../../../@types/props/ViewUser.props";
import { api } from "../../../../../utils/api";
import SnackbarMessage from "../../../../shared/snackbar/SnackbarMessage";

function ReligionDetailsForm({ user }: IViewUserProps) {
	const queryParams = new URLSearchParams(window.location.search);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const userId = queryParams.get("_id");
	const [religiousDetails, setReligiousDetails] = useState({
		islamic_education: user?.islamic_education || "",
		salah: user?.salah || "",
		sawum: user?.sawum || "",
		hijab_maintain: user?.hijab_maintain || "",
		religious: user?.religious || ""
	});

	const [editable, setEditable] = useState(true);

	const handleReligiousDetailsChange = (event: any) => {
		const { id, value } = event.target;
		setReligiousDetails({ ...religiousDetails, [id]: value });
	};

	const handleEditClick = () => {
		setEditable(true);
	};

	const handleSaveClick = useCallback(async () => {
		setEditable(false);
		console.log("save clicked--->", religiousDetails);
		const payload = { userDetails: religiousDetails, userObjectId: userId };
		console.log("Changes value---->", religiousDetails);
		const response = await api.user.updateUserDetails(payload);
		// console.log("Response --->", response);
		if (response) {
			setSnackbarMessage("Religious details Edited successfully!");
			setSnackbarOpen(true);
		}
	}, [religiousDetails, userId]);

	return (
		<>
			<div className="edit-btn-container">
				{/* <Button
					onClick={handleEditClick}
					sx={{ marginRight: 1 }}
					variant="contained"
					className="button-violet"
					endIcon={<EditIcon />}
					disabled={editable}
				>
					Edit
				</Button> */}
				<Button
					onClick={handleSaveClick}
					variant="contained"
					className="button-violet"
					endIcon={<SaveIcon />}
					disabled={!editable}
				>
					Save
				</Button>
			</div>
			<h3>Religion Details</h3>
			<Paper elevation={3} sx={{ padding: 3, maxWidth: "100%", margin: "auto", paddingLeft: 5 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6}>
						<Box component="label" htmlFor="islamic_education" sx={{ fontWeight: "bold" }}>
							Islamic Education:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="islamic_education"
							variant="outlined"
							value={religiousDetails.islamic_education}
							onChange={handleReligiousDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="salah" sx={{ fontWeight: "bold" }}>
							Salah:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="salah"
							variant="outlined"
							value={religiousDetails.salah}
							onChange={handleReligiousDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="sawum" sx={{ fontWeight: "bold" }}>
							Sawum:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="sawum"
							variant="outlined"
							value={religiousDetails.sawum}
							onChange={handleReligiousDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="hijab_maintain" sx={{ fontWeight: "bold" }}>
							Hijab Maintain:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="hijab_maintain"
							variant="outlined"
							value={religiousDetails.hijab_maintain}
							onChange={handleReligiousDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="religious" sx={{ fontWeight: "bold" }}>
							Religious:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="religious"
							variant="outlined"
							value={religiousDetails.religious}
							onChange={handleReligiousDetailsChange}
							disabled={!editable}
						/>
					</Grid>
				</Grid>
				<SnackbarMessage
					snackbarOpen={snackbarOpen}
					snackbarMessage={snackbarMessage}
					severity={snackbarSeverity}
					handleClose={() => setSnackbarOpen(false)}
				/>
			</Paper>
		</>
	);
}

export default ReligionDetailsForm;
