import { useCallback, useContext, useEffect, useState } from "react";

import UIContext from "../../../../contexts/uiContext/UIContext";
import Form from "../../../shared/form/Form";
import { IFieldList } from "../../../../@types/interface/fieldList.interface";

import { Box, Button, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { IPlans } from "../../../../@types/interface/plans.interface";
import SnackbarMessage from "../../../shared/snackbar/SnackbarMessage";
import { addPlansFormFields } from "../../../../constants/planDetailsFormFields/planDetailsFormFields";
import { api } from "../../../../utils/api";

const AddPlansForm = () => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const [errorObject, setErrorObject] = useState({});
	const [formObject, setFormObject] = useState<IPlans>({
		plan_name: "",
		plan_price: 0,
		plan_validity: 0,
		chat_count: 0
	});
	const handleChangeText = (id: string, value: string) => {
		const newValue = id === "plan_name" ? value : Number(value);
		setFormObject({
			...formObject,
			[id]: newValue
		});
	};
	const handleSubmit = useCallback(
		async (event: React.FormEvent) => {
			event.preventDefault();
			try {
				//call post addPlans api here
				const response = await api.admin.createPlan(formObject);
				if (response) {
					setFormObject({
						plan_name: "",
						plan_price: 0,
						plan_validity: 0,
						chat_count: 0
					});
					setSnackbarMessage("Plan details added successfully!");
					setSnackbarSeverity("success");
					setSnackbarOpen(true);
				}
			} catch (error) {
				console.log("Submission error: ", error);
				setSnackbarMessage("Failed to add plan");
				setSnackbarSeverity("error");
				setSnackbarOpen(true);
			}
		},
		[formObject]
	);
	const { setDashboardHeader } = useContext(UIContext);
	useEffect(() => {
		setDashboardHeader("Plans & Pricing");
	}, [setDashboardHeader]);

	return (
		<Box component="form" onSubmit={handleSubmit}>
			<Typography variant="h4" component="h2" gutterBottom margin="20px 0 0 10px">
				Add Plans Details
			</Typography>
			<Form
				fieldList={addPlansFormFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={errorObject}
			/>
			<div className="add-btn">
				<Button
					variant="contained"
					tabIndex={-1}
					className="button-pink"
					type="submit"
					endIcon={<SendIcon />}
					sx={{
						mt: 2
					}}
				>
					Submit
				</Button>
			</div>
			<SnackbarMessage
				snackbarOpen={snackbarOpen}
				snackbarMessage={snackbarMessage}
				severity={snackbarSeverity}
				handleClose={() => setSnackbarOpen(false)}
			/>
		</Box>
	);
};

export default AddPlansForm;
