const assets = {
	images: {
		logo: require("./images/company-logo.png"),
		company: require("./images/Scriptures.png")
	}
};

export default assets;

export { default as newleeyBrandLogo } from "../assets/brandLogo/BrandLogo";

// Client Brand Logo
export { default as newleeyBlueBrandLogo } from "./clientBrandLogo/logo3.svg";

//Auth Images

// export { default as forgetPasswordUsernameImage } from "./forget_password_username_image.png";
// export { default as password_reset } from "./password_reset.png";
// export { default as registrationPageImageA } from "./registration_page_image_A.png";
// export { default as registrationPageImageB } from "./registration_page_image_B.png";
// export { default as registrationSuccessImage } from "./registration_success.png";

// Notification
export { default as notificationSound } from "./notification/notification_sound.mp3";

// Pay Per Lead Portal (Non Subscription base member portal)

// Subscription based Member Portal

// https://newleey.com/
