import { AppBar, IconButton, Menu, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
// import sizeConfigs from "../../../configs/sizeConfigs";
import { ChevronLeft, Logout } from "@mui/icons-material";
import { useContext } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import image from "../../../assets/images/house.jpg";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import MenuList from "@mui/material/MenuList";
import { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/system";
import AuthContext from "../../../contexts/authContext/authContext";

import { ROLES } from "../../../constants/roles/Roles";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import sizeConfigs from "../../../config/sizeConfigs";
import Chip from "@mui/material/Chip";
import DropdownMenu from "../../shared/dropdownMenu/DropdownMenu";

interface TopbarProps {
	sidebarOpen: boolean;
	toggleSidebar: () => void; // Add a toggleSidebar prop of type function
}

const Topbar: React.FC<TopbarProps> = ({ sidebarOpen, toggleSidebar }) => {
	const { dashboardHeader } = useContext(UIContext);
	const { user } = useContext(AuthContext);

	const allowedRolesForDropdown = [ROLES.super_admin];

	return (
		<AppBar
			position="fixed"
			elevation={3}
			sx={{
				width: sidebarOpen ? `calc(100% - ${sizeConfigs.sidebar.width})` : "100%",
				ml: sidebarOpen ? sizeConfigs.sidebar.width : 0,
				backgroundColor: "#FFFFFF",
				color: colorConfigs.topbar.color
			}}
		>
			<Toolbar
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center"
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
						// gap: "10px",
						// padding: "10px"
					}}
				>
					<IconButton onClick={toggleSidebar}>{!sidebarOpen && <MenuOutlinedIcon />}</IconButton>
					<Typography variant="h6" style={{ color: "#333333" }}>
						{dashboardHeader}
					</Typography>
				</div>

				{/* Add a toggle button for the sidebar */}

				{/* <Button variant="contained" sx={{ backgroundColor: "#004d99", fontFamily: "Railway, sans-serif"}}>Builder</Button> */}
				{/* {user?.role === ROLES.super_admin ? (
          <Dropdown />
        ) : (
          <div
            className="button-group"
            style={{
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button className="green-contained-button" variant="outlined">
              {builderDetails?.builder_name}
            </Button>
            <Dropdown />
          </div>
        )} */}
				{/* {allowedRolesForDropdown ? (
          <Dropdown />
        ) : (
         ""
        )} */}
				<div
					className="button-group"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						gap: "15px"
					}}
				>
					<Chip
						label={user?.role}
						style={{
							backgroundColor: "#ffe6f0",
							color: "#ff3377",
							fontFamily: "Arial, sans-serif",
							fontWeight: "bold",
							fontSize: "14px"
						}}
					/>
					<NotificationsOutlinedIcon sx={{ fontSize: "30px", color: "gray" }} />

					<DropdownMenu />
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default Topbar;
