import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import PersonalDetailsForm from "./personalDetailsForm/PersonalDetailsForm";
import JobDetailsForm from "./jobDetailsForm/JobDetailsForm";
import FamilyDetailsForm from "./familyDetailsForm/FamilyDetailsForm";
import ReligionDetailsForm from "./religionDetailsForm/ReligionDetailsForm";
import { api } from "../../../../utils/api";
import { IUserDetails } from "../../../../@types/interface/userDetails.interface";

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}
const UserDetails = () => {
	const [value, setValue] = useState<number>(0);
	const [user, setUser] = useState<IUserDetails | null>(null);

	const queryParams = new URLSearchParams(window.location.search);
	const userId = queryParams.get("_id");

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	const getUserDetails = useCallback(async () => {
		const payload = {
			_id: userId
		};
		const userDetails = await api.admin.getUserDetails(payload);
		console.log("result :", userDetails);
		setUser(userDetails);
	}, [userId]);

	useEffect(() => {
		getUserDetails();
	}, [getUserDetails]);
	return (
		<Box sx={{ width: "100%" }}>
			<Tabs value={value} onChange={handleChange} aria-label="employee details tabs">
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Personal Details" {...a11yProps(0)} />
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Job Details" {...a11yProps(1)} />
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Religion Details" {...a11yProps(2)} />
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Family Details" {...a11yProps(3)} />
			</Tabs>
			<TabPanel value={value} index={0}>
				{user && <PersonalDetailsForm user={user} />}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{user && <JobDetailsForm user={user} />}
			</TabPanel>
			<TabPanel value={value} index={2}>
				{user && <ReligionDetailsForm user={user} />}
			</TabPanel>
			<TabPanel value={value} index={3}>
				{user && <FamilyDetailsForm user={user} />}
			</TabPanel>
		</Box>
	);
};

export default UserDetails;
