import React, { useState } from "react";
import { Box, TextField, Typography, Button, Paper } from "@mui/material";

const NotificationForm = ({
	handleImageUpload,
	handleNotificationDetailsChange,
	notificationDetails,
	image
}: {
	image: string | null;
	notificationDetails: any;
	handleNotificationDetailsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
	return (
		<Paper
			elevation={3}
			sx={{
				margin: "auto",
				padding: 3,
				borderRadius: 2,
				marginTop: 2
			}}
		>
			<Typography variant="h6" align="left" gutterBottom>
				Send Notification
			</Typography>
			<Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
				{/* Notification Title */}
				<TextField
					label="Notification Title"
					variant="outlined"
					name="notification_title"
					value={notificationDetails.notification_title}
					onChange={handleNotificationDetailsChange}
					fullWidth
				/>

				{/* Notification Body */}
				<TextField
					label="Notification Body"
					variant="outlined"
					multiline
					rows={4}
					name="notification_body"
					value={notificationDetails.notification_body}
					onChange={handleNotificationDetailsChange}
					fullWidth
				/>

				{/* Image Upload */}
				<input
					accept="image/*"
					style={{ display: "none" }}
					id="image-upload"
					type="file"
					onChange={handleImageUpload}
				/>
				<label htmlFor="image-upload">
					<Button variant="contained" component="span">
						Upload Image
					</Button>
				</label>
				{image && (
					<Box
						component="img"
						sx={{
							height: 200,
							width: 300,
							marginTop: 2,
							borderRadius: 2
						}}
						alt="Uploaded Image"
						src={image}
					/>
				)}
			</Box>
		</Paper>
	);
};

export default NotificationForm;
