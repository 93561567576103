import { RouteType } from "./config";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import MapIcon from "@mui/icons-material/Map";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookIcon from "@mui/icons-material/Book";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { AdminPanelSettingsRounded, People, ScatterPlotSharp, Settings } from "@mui/icons-material";

import Book from "@mui/icons-material/Book";

const adminRoutes: RouteType[] = [
	// {
	// 	path: "/admin",
	// 	element: <AdminPage />,
	// 	state: "admin"
	// },
	// {
	// 	path: "/admin/admin-enquiry",
	// 	element: <NewEnquiry />,
	// 	state: "customerEnquiry",
	// 	sidebarProps: {
	// 		displayText: "Customer Enquiry",
	// 		icon: <LiveHelpOutlinedIcon />
	// 	}
	// },
	// {
	// 	path: "/admin/employee",
	// 	element: <EmployeeManagemnet />,
	// 	state: "employee",
	// 	sidebarProps: {
	// 		displayText: "Employee Management",
	// 		icon: <PeopleOutlinedIcon />
	// 	}
	// },
	// {
	// 	path: "/admin/Plots",
	// 	element: <AdminPlots />,
	// 	state: "allPlots",
	// 	sidebarProps: {
	// 		displayText: "All Plots",
	// 		icon: <MapIcon />
	// 	}
	// },
	// {
	// 	path: "/admin/project-details",
	// 	element: <ProjectDetails />,
	// 	state: "plots"
	// },
	// {
	// 	path: "/admin/enquiry-details",
	// 	element: <EnquiryDetails mode="ENQUIRY" />,
	// 	state: "plots"
	// },
	// {
	// 	path: "/admin/forwarded-enquiry-details",
	// 	element: <EnquiryDetails mode="FORWARDED-ENQUIRY" />,
	// 	state: "plots"
	// },
	// {
	// 	path: "/admin/add-follow-up",
	// 	element: <AddFollowUp />,
	// 	state: "followup"
	// },
	// {
	// 	path: "/admin/add-reports",
	// 	element: <AddReport />,
	// 	state: "reports"
	// },
	// {
	// 	path: "/admin/forwarded_enquiry",
	// 	element: <ForwardedEnquiry />,
	// 	state: "forwarded_enqury",
	// 	sidebarProps: {
	// 		displayText: "Forwared Enquiry",
	// 		icon: <ArrowForwardOutlinedIcon />
	// 	}
	// },
	// {
	// 	path: "/admin/assigned_project",
	// 	element: <AssignedProject />,
	// 	state: "assigned_projects",
	// 	sidebarProps: {
	// 		displayText: "Assigned Projects",
	// 		icon: <AssignmentTurnedInOutlinedIcon />
	// 	}
	// },
	// {
	//   path: "/admin/add-plots",
	//   element: <AddPlotsForm />,
	//   state: "plots",
	// },
	// {
	// 	path: "/admin/reports",
	// 	element: <Reports />,
	// 	state: "reports",
	// 	sidebarProps: {
	// 		displayText: "Reports",
	// 		icon: <Book />
	// 	}
	// },
	// {
	// 	path: "/admin/follow-up",
	// 	element: <FollowUpCalendar />,
	// 	state: "followup",
	// 	sidebarProps: {
	// 		displayText: "Follow up",
	// 		icon: <CalendarMonthIcon />
	// 	}
	// },
	// {
	//   path: "/admin/select-plots",
	//   element: <UploadLayout />,
	//   state: "plots",
	// },
	// {
	// 	path: "/admin/add-employee",
	// 	element: <AddEmployee />,
	// 	state: "admin"
	// }
];

export default adminRoutes;
