import { IFieldList } from "../../@types/interface/fieldList.interface";

export const addAppDetailsFormFields: IFieldList[] = [
	{
		id: "application_name",
		type: "TEXT",
		label: "App Name",
		placeHolder: "Enter app name",
		required: true
	},
	{
		id: "app_details",
		type: "TEXT",
		label: "App Details",
		placeHolder: "Enter app details",
		required: true
	},
	{
		id: "application_size",
		type: "TEXT",
		label: "App Size",
		placeHolder: "Enter app size",
		required: true
	},
	{
		id: "application_version",
		type: "TEXT",
		label: "App version",
		placeHolder: "Enter app version",
		required: true
	},
	{
		id: "is_active",
		type: "SWITCH",
		label: "Is App Active"
	}
];
