import { Button } from "@mui/material";
import React, { useCallback, useContext } from "react";

import { IUserCellRendererProps } from "../../../../@types/props/UserCellRenderer.props";
import { api } from "../../../../utils/api";
import AuthContext from "../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../constants/roles/Roles";

const DeleteUserRenderer = ({ data }: { data: IUserCellRendererProps }) => {
	const { user } = useContext(AuthContext);
	const handleDeleteConfirm = useCallback(() => {
		if (window.confirm("Are you sure you want to delete?")) {
			handleDeleteUser();
		}
	}, []);
	const handleDeleteUser = async () => {
		const payload = {
			userId: data._id
		};
		const response = await api.user.deleteUserDetails(payload);
		if (response) {
			alert("User deleted successfully");
			window.location.reload();
		}
		console.log("responsedelete--->", response);
	};
	return (
		<div>
			{user?.role === ROLES.admin ? (
				<Button style={{ backgroundColor: "white", color: "lightgray" }}>No Access</Button>
			) : (
				<Button variant="outlined" className="red-outlined-button" onClick={handleDeleteConfirm}>
					Delete User
				</Button>
			)}
		</div>
	);
};

export default DeleteUserRenderer;
