import { Button, IconButton, Paper, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CloseIcon from "@mui/icons-material/Close";
import "./profile.css";
import UIContext from "../../../contexts/uiContext/UIContext";

const Profile = () => {
	const { setDashboardHeader } = useContext(UIContext);

	useEffect(() => {
		setDashboardHeader("Profile Settings");
	}, [setDashboardHeader]);
	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
			<Paper sx={{ width: "500px", padding: "20px" }}>
				<div className="value-with-labels">
					<div className="icon-label-flex">
						<IconButton>
							<AccountBoxIcon />
						</IconButton>
						<h3>Full Name:</h3>
					</div>

					<TextField
						variant="standard"
						value={"Nelson Banks"}
						sx={{ width: "300px" }}
						className="text-value"
					/>
				</div>
				<div className="value-with-labels">
					<div className="icon-label-flex">
						<IconButton>
							<AssignmentTurnedInIcon />
						</IconButton>
						<h3>Role:</h3>
					</div>

					<TextField
						variant="standard"
						value={"Super Admin"}
						sx={{ width: "300px" }}
						className="text-value"
					/>
				</div>
				<div className="value-with-labels">
					<div className="icon-label-flex">
						<IconButton>
							<EmailIcon />
						</IconButton>
						<h3>Email:</h3>
					</div>

					<TextField
						variant="standard"
						value={"nelson1234@gmail.com"}
						sx={{ width: "300px" }}
						className="text-value"
					/>
				</div>
				<div className="value-with-labels">
					<div className="icon-label-flex">
						<IconButton>
							<LocalPhoneIcon />
						</IconButton>
						<h3>Phone:</h3>
					</div>

					<TextField
						variant="standard"
						value={"+1-123-456-789"}
						sx={{ width: "300px" }}
						className="text-value"
					/>
				</div>

				<div className="edit-button-container">
					<Button variant="contained" className="button-pink" endIcon={<ModeEditOutlineIcon />}>
						Edit
					</Button>
					<Button endIcon={<CloseIcon />} className="violet-outlined">
						Cancel
					</Button>
				</div>
			</Paper>
		</div>
	);
};

export default Profile;
