import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UIContext from "../../../contexts/uiContext/UIContext";
import DataGrid from "../../shared/dataGrid/DataGrid";
import { PaymentColDefs } from "../../../constants/payment/paymentColDefs";

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import { api } from "../../../utils/api";
import BasicPagination from "../../shared/basicPagination/BasicPagination";

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

const Payment = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const [value, setValue] = useState<number>(0);
	const [paymentRowData, setPaymentRowData] = useState([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [timeSpan, setTimeSpan] = useState<number>(1);
	const [paymentStatus, setPaymentStatus] = useState<string>("PAID");
	const [bottomRow, setBottomRow] = useState([
		{
			tran_id: "TOTAL",
			price: 0
		}
	]);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		newValue === 0
			? setPaymentStatus("PAID")
			: newValue === 1
				? setPaymentStatus("CANCELLED")
				: setPaymentStatus("FAILED");
		setValue(newValue);
	};
	const handleTimeSpanChange = (event: SelectChangeEvent<number>) => {
		setTimeSpan(event.target.value as number);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
	};
	const onFilterChange = () => {
		//onFilterChange handler here
	};

	const getPymentDetails = useCallback(async () => {
		try {
			const payload = {
				timeSpan: timeSpan,
				page: currentPage,
				payment_status: paymentStatus
			};
			const result = await api.payment.getPaymentList(payload); // api call to get payment using filters
			console.log(result);
			if (result) {
				setPaymentRowData(result.payments);
				setTotalPages(result.pagination.totalPages);
				setBottomRow([
					{
						tran_id: "TOTAL",
						price: result.totalPrice
					}
				]);
			}
		} catch (error) {
			console.error("Error fetching payment details:", error);
		}
	}, [currentPage, value, timeSpan]);

	useEffect(() => {
		setDashboardHeader("Payment");
		getPymentDetails();
	}, [setDashboardHeader, getPymentDetails]);

	return (
		<div>
			<Tabs value={value} onChange={handleChange} aria-label="Payment details tabs">
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Paid" {...a11yProps(0)} />
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Cancelled" {...a11yProps(1)} />
				<Tab sx={{ fontSize: "1rem", fontWeight: "500" }} label="Failed" {...a11yProps(2)} />
			</Tabs>
			<div style={{ display: "flex", alignItems: "center", gap: 5, padding: "10px" }}>
				<InputLabel id="time-period-label">Time Period - </InputLabel>
				<Select
					labelId="time-period-label"
					id="time-period-select"
					value={timeSpan}
					// label="Time Period"
					onChange={handleTimeSpanChange}
					sx={{
						height: "2rem"
					}}
				>
					<MenuItem value={1}>1 Day</MenuItem>
					<MenuItem value={7}>7 Days</MenuItem>
					<MenuItem value={30}>1 Month</MenuItem>
					<MenuItem value={180}>6 Months</MenuItem>
					<MenuItem value={365}>1 Year</MenuItem>
				</Select>
			</div>
			<TabPanel>
				<DataGrid
					colDefs={PaymentColDefs}
					rowData={paymentRowData}
					key={0}
					onFilterChange={onFilterChange}
					pinnedBottomRowData={bottomRow}
				/>
				<BasicPagination currentPage={currentPage} handlePageChange={handlePageChange} pageCount={totalPages} />
			</TabPanel>
		</div>
	);
};

export default Payment;
