export interface FieldCategories {
	personalDetails: string[];
	educationalDetails: string[];
	religiousDetails: string[];
	familyDetails: string[];
	financialDetails: string[];
	accountDetails: string[];
}

export const fieldCategories: FieldCategories = {
	personalDetails: [
		"full_name",
		"email",
		"mobile",
		"age",
		"gender",
		"marital_status",
		"country",
		"state",
		"height",
		"weight",
		"body_color",
		"eye_color",
		"hair_color",
		"profile_image_url"
	],
	educationalDetails: ["education", "islamic_education"],
	religiousDetails: ["salah", "sawum", "hijab_maintain", "religious"],
	familyDetails: [
		"fathers_name",
		"fathers_occupation",
		"mothers_name",
		"mothers_occupation",
		"no_of_brothers",
		"no_of_sisters",
		"total_family_member"
	],
	financialDetails: ["financial_condition", "monthly_income", "work_place", "occupation"],
	accountDetails: [
		"status",
		"account_private",
		"private_status",
		"is_verified",
		"view_count",
		"message_limit",
		"acount_status"
	]
};
