import { colors } from "@mui/material";

const colorConfigs = {
	sidebar: {
		bg: "#fff7e6",
		color: "#404040",
		hoverBg: "#ffe6b3",
		activeBg: "#ffd480",
		border: " #808080",
		hoverColor: "#000",
		activeColor: "#000"
	},
	adminSidebar: {
		bg: "#E7F6F2",
		color: "#eeeeee",
		hoverBg: "blue",
		activeBg: "red"
	},
	topbar: {
		bg: "#EFFFFF",
		color: "#000"
	},
	mainBg: colors.grey["100"]
};

export default colorConfigs;
