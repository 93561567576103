import { api } from "../../api";

const useEditMessageLimit = () => {
	const editMessageLimit = async (payload: any) => {
		try {
			const result = await api.user.updateUserDetails(payload);
			if (result) {
				alert("Message limit updated");
			}
		} catch (error) {
			throw new Error("Error updating user details");
		}
	};
	return { editMessageLimit };
};

export default useEditMessageLimit;
