import React, { useCallback, useState } from "react";
import { Grid, Paper, TextField, Box, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { IViewUserProps } from "../../../../../@types/props/ViewUser.props";
import { api } from "../../../../../utils/api";
import SnackbarMessage from "../../../../shared/snackbar/SnackbarMessage";

function JobDetailsForm({ user }: IViewUserProps) {
	const queryParams = new URLSearchParams(window.location.search);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const userId = queryParams.get("_id");
	const [jobDetails, setJobDetails] = useState({
		occupation: user?.occupation || "",
		education: user?.education || "",
		work_place: user?.work_place || "",
		monthly_income: user?.monthly_income || ""
	});
	const [editable, setEditable] = useState(true);

	const handleJobDetailsChange = (event: any) => {
		const { id, value } = event.target;
		setJobDetails({ ...jobDetails, [id]: value });
	};
	const handleEditClick = () => {
		setEditable(true);
	};

	const handleSaveClick = useCallback(async () => {
		setEditable(false);
		console.log("save clicked--->", jobDetails);
		const payload = { userDetails: jobDetails, userObjectId: userId };
		console.log("Changes value---->", jobDetails);
		const response = await api.user.updateUserDetails(payload);
		// console.log("Response --->", response);
		if (response) {
			setSnackbarMessage("Job details Edited successfully!");
			setSnackbarOpen(true);
		}
	}, [jobDetails, userId]);

	return (
		<>
			<div className="edit-btn-container">
				{/* <Button
					onClick={handleEditClick}
					sx={{ marginRight: 1 }}
					variant="contained"
					className="button-violet"
					endIcon={<EditIcon />}
					disabled={editable}
				>
					Edit
				</Button> */}
				<Button
					onClick={handleSaveClick}
					variant="contained"
					className="button-violet"
					endIcon={<SaveIcon />}
					disabled={!editable}
				>
					Save
				</Button>
			</div>
			<h3>Job Details</h3>
			<Paper elevation={3} sx={{ padding: 3, maxWidth: "100%", margin: "auto", paddingLeft: 5 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6}>
						<Box component="label" htmlFor="occupation" sx={{ fontWeight: "bold" }}>
							Occupation:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="occupation"
							variant="outlined"
							value={jobDetails.occupation}
							onChange={handleJobDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="education" sx={{ fontWeight: "bold" }}>
							Education:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="education"
							variant="outlined"
							value={jobDetails.education}
							onChange={handleJobDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="work_place" sx={{ fontWeight: "bold" }}>
							Work Place:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="work_place"
							variant="outlined"
							value={jobDetails.work_place}
							onChange={handleJobDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="monthly_income" sx={{ fontWeight: "bold" }}>
							Monthly Income:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="monthly_income"
							variant="outlined"
							value={jobDetails.monthly_income}
							onChange={handleJobDetailsChange}
							disabled={!editable}
						/>
					</Grid>
				</Grid>
				<SnackbarMessage
					snackbarOpen={snackbarOpen}
					snackbarMessage={snackbarMessage}
					severity={snackbarSeverity}
					handleClose={() => setSnackbarOpen(false)}
				/>
			</Paper>
		</>
	);
}

export default JobDetailsForm;
