import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { blue, green } from "@mui/material/colors";
import "./pagination.css";
import { BasicPaginationPropsType } from "../../../@types/props/basicPaginationProps.types";

const BasicPagination = ({ currentPage, handlePageChange, pageCount }: any) => {
	const color = blue[400];
	return (
		<div
			style={{
				alignItems: "right",
				justifyContent: "right",
				display: "flex",
				marginTop: "20px"
			}}
		>
			<Pagination
				shape="rounded"
				count={pageCount}
				page={currentPage}
				variant="outlined"
				onChange={handlePageChange}
				color="primary"
			/>
		</div>
	);
};

export default BasicPagination;
