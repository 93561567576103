/* eslint-disable arrow-parens */
import { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import Form from "../../../shared/form/Form";
import { Box, Button, Chip, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { api } from "../../../../utils/api";
import SnackbarMessage from "../../../shared/snackbar/SnackbarMessage";
import { FormObject } from "../../../../@types/interface/userForm.interface";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
	familyStatusFields,
	jobFields,
	personalDetailsFields,
	religiousFields
} from "../../../../constants/addUserDetailsFormFields/addUserFormFields";
import { styled } from "@mui/material/styles";
import { validateInputFields } from "../../../../utils/commonFunctions/validateAddUserFields";
import { IUserDetails } from "../../../../@types/interface/userDetails.interface";
import { userFormObject } from "../../../../constants/defaultFormInputs/defaultUserForm";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1
});

const AddUserForm = () => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [errorObject, setErrorObject] = useState({
		full_name: "",
		email: "",
		password: "",
		mobile: "",
		no_of_brothers: "",
		no_of_sisters: ""
	});
	const [formObject, setFormObject] = useState<Partial<IUserDetails>>(userFormObject);

	const handleChangeText = (id: string, value: string) => {
		setFormObject({
			...formObject,
			[id]: value
		});
		const error = validateInputFields(id, value);
		setErrorObject({
			...errorObject,
			[id]: error || ""
		});
	};

	const handleSubmit = useCallback(
		async (event: { preventDefault: () => void }) => {
			event.preventDefault();
			//Form vaidation
			console.log("errorObject-->", errorObject);
			console.log("FormObject-->", formObject);

			if (
				errorObject.full_name ||
				errorObject.email ||
				errorObject.password ||
				errorObject.mobile ||
				errorObject.no_of_brothers ||
				errorObject.no_of_sisters
			) {
				setSnackbarMessage("Enter user Details correctly!");
				setSnackbarSeverity("error");
				setSnackbarOpen(true);
				return;
			}
			try {
				const formData = new FormData();
				formData.append("userDetails", JSON.stringify(formObject));
				if (uploadedFile) {
					formData.append("profile_image", uploadedFile);
				}

				console.log("Checking form data--->", formData);
				const response = await api.user.addUserDetails(formData);
				console.log("Response-->", response);
				if (response) {
					// alert("Success");
					setFormObject(userFormObject);
					// Show success snackbar
					setSnackbarMessage("User details added successfully!");
					setSnackbarSeverity("success");
					setSnackbarOpen(true);
				}
			} catch (error) {
				// Handle error (you might want to show an error snackbar here)
				setSnackbarMessage("Failed to add User details!");
				setSnackbarSeverity("error");
				setSnackbarOpen(true);
				console.error("Submission error: ", error);
			}
		},
		[formObject, uploadedFile]
	);
	const { setDashboardHeader } = useContext(UIContext);

	useEffect(() => {
		setDashboardHeader("All Users");
	}, [setDashboardHeader]);
	return (
		<Box component="form" onSubmit={handleSubmit}>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "end",
					alignItems: "center",
					gap: "20px",
					position: "absolute",
					right: "0",
					padding: "20px"
				}}
			>
				<Button
					style={{
						marginTop: 20,
						marginBottom: 10,
						backgroundColor: "#ec4899"
					}}
					component="label"
					role={undefined}
					variant="contained"
					tabIndex={-1}
					// className="btn"
					startIcon={<CloudUploadIcon />}
				>
					Upload Image
					<VisuallyHiddenInput
						type="file"
						onChange={(event) => {
							const file = event.target.files?.[0];
							if (file) {
								setUploadedFile(file);
							}
						}}
						required
					/>
				</Button>

				{uploadedFile && (
					<Chip
						label={uploadedFile.name}
						onDelete={() => setUploadedFile(null)}
						variant="outlined"
						sx={{ marginTop: 1 }}
					/>
				)}
			</div>
			<Typography variant="h4" component="h2" gutterBottom margin="20px 0 0 10px">
				Add User Details
			</Typography>

			<Typography variant="h6" component="h3" gutterBottom margin="20px 0 0 10px">
				Personal Details
			</Typography>
			<Form
				fieldList={personalDetailsFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={errorObject}
			/>

			<Typography variant="h6" component="h3" gutterBottom margin="20px 0 0 10px">
				Job and Occupation
			</Typography>
			<Form
				fieldList={jobFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={errorObject}
			/>

			<Typography variant="h6" component="h3" gutterBottom margin="20px 0 0 10px">
				Religious Information
			</Typography>
			<Form
				fieldList={religiousFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={errorObject}
			/>

			<Typography variant="h6" component="h3" gutterBottom margin="20px 0 0 10px">
				Family Status
			</Typography>
			<Form
				fieldList={familyStatusFields}
				handleChangeText={handleChangeText}
				object={formObject}
				errorObject={errorObject}
			/>

			<div className="add-btn">
				<Button
					variant="contained"
					tabIndex={-1}
					className="button-pink"
					type="submit"
					endIcon={<SendIcon />}
					sx={{ mt: 2 }}
				>
					Submit
				</Button>
			</div>

			<SnackbarMessage
				snackbarOpen={snackbarOpen}
				snackbarMessage={snackbarMessage}
				severity={snackbarSeverity}
				handleClose={() => setSnackbarOpen(false)}
			/>
		</Box>
	);
};

export default AddUserForm;
