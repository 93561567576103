import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";

const { get } = request;

const initialRoute = "plans";
export const getPlanByID = async (plan_id: string) => {
	try {
		if (!plan_id) {
			throw new Error("Plan ID is required.");
		}
		const endpoint = `${initialRoute}/get-plan-info/${plan_id}`;
		const response = await get(endpoint, {
			...headers
		});
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error("Failed to fetch builder by ID.");
	} catch (error) {
		console.log(error);
		throw error;
	}
};
