import { ColDef } from "ag-grid-community";
import PlansCellRenderer from "../../components/pages/plans/PlansCellRenderer";

export const PlansColDefs: ColDef[] = [
	{
		field: "plan_name",
		headerName: "Plan Name",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "plan_price",
		headerName: "Plan Price",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "offer_price",
		headerName: "Plan Offer Price",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "plan_validity",
		headerName: "Plan Validity",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "chat_count",
		headerName: "Chat Count",
		suppressSizeToFit: true,
		flex: 1
	},
	{
		field: "action",
		headerName: "Action",
		cellRenderer: PlansCellRenderer
	}
];
