import React from "react";
import { IUserDetails } from "../../../@types/interface/userDetails.interface";

// Custom Cell Renderer to format date
const FormattedDateCellRenderer = ({ data }: { data: IUserDetails }) => {
	// Format the date to display in Month Date, Year (e.g., November 14, 2024)
	const formattedDate = new Date(data.updatedAt).toLocaleDateString("en-US", {
		month: "numeric",
		year: "numeric",
		day: "numeric"
	});

	return <div>{formattedDate}</div>;
};

export default FormattedDateCellRenderer;
