import { useCallback, useEffect, useState } from "react";
import { IUserDetails } from "../../../@types/interface/userDetails.interface";
import { formatFilters } from "../../commonFunctions/formatApiFilters";
import { api } from "../../api";
import { IUseGetUserListProps } from "../../../@types/props/hookProps/useGetUserList/UseGetUserList.props";

const useGetUserList = ({ filter, userDataPagination, handleSetPagination }: IUseGetUserListProps) => {
	const [rowData, setRowData] = useState<Partial<IUserDetails>[]>([]);
	const getUserList = useCallback(async () => {
		try {
			const formattedFilter = formatFilters(filter);
			const payload = {
				...formattedFilter,
				page: userDataPagination.currentPage
			};
			const response = await api.user.getUserList(payload); // api call to get user using filters
			console.log(response);

			if (response) {
				setRowData(response.result);
				handleSetPagination(response.pagination);
			}
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	}, [filter, userDataPagination.currentPage]);

	useEffect(() => {
		getUserList();
	}, [getUserList]);
	return rowData;
};

export default useGetUserList;
