import React, { useContext, useState } from "react";
import { Avatar, Drawer, List, Stack, Toolbar, IconButton, Collapse } from "@mui/material";
import { Menu, ChevronLeft, ChevronRight } from "@mui/icons-material";
import colorConfigs from "../../../config/colorConfigs";
import sizeConfigs from "../../../config/sizeConfigs";
import appRoutes from "../../../routes/appRoutes";
import SidebarItem from "./sidebarItem/SidebarItem";
import SidebarItemCollapse from "./sidebarItemCollapse/SidebarItemCollapse";

import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import assets from "../../../assets";
import { ClientBrandLogo } from "../";
import Topbar from "../../layout/topbar/Topbar";
import shohozshadiLogo from "../../../assets/images/svg/logo3.svg";

interface SideBarProps {
	toggleSidebar: () => void;
	opens: boolean;
}

function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name)
		},
		children: name.length > 1 ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}` : "SA"
	};
}

const Sidebar: React.FC<SideBarProps> = ({ toggleSidebar, opens }) => {
	const { user } = useContext(AuthContext);
	const [open, setOpen] = useState(true);
	// const [collapseOpen, setCollapseOpen] = useState(false);

	const handleToggleSidebar = () => {
		setOpen(!open);
		toggleSidebar();
	};

	// const handleToggleCollapse = () => {
	//   setCollapseOpen(!collapseOpen);
	// };
	// console.log("Checking for the name ---->", user);
	return (
		<>
			<Topbar sidebarOpen={open} toggleSidebar={handleToggleSidebar} />
			<Drawer
				variant="permanent"
				sx={{
					width: open ? sizeConfigs.sidebar.width : 0,
					flexShrink: 0,
					transition: "width 0.2s ease-in-out",
					"& .MuiDrawer-paper": {
						width: open ? sizeConfigs.sidebar.width : 0,
						boxSizing: "border-box",
						borderRight: "0px",
						backgroundColor: colorConfigs.sidebar.bg,
						color: colorConfigs.sidebar.color,
						overflowX: "hidden",
						boxShadow: "0 2px 8px 2px gray"
					}
				}}
			>
				<Toolbar
					style={{
						display: "flex",
						justifyContent: "right",
						alignItems: "right"
					}}
				>
					{open && (
						<IconButton
							onClick={handleToggleSidebar}
							className="sidebar-toggle-btn"
							style={{ color: "white", backgroundColor: "#ff3377" }}
						>
							<ChevronLeft />
						</IconButton>
					)}
				</Toolbar>

				{/*brand logo*/}
				{/* <div>{newleeyBrandLogo}</div> */}

				{/* <div>{newleeyBlueBrandLogo}</div> */}
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "50px"
					}}
				>
					<img src={shohozshadiLogo} style={{ width: "80%" }} />
				</div>

				{/* <ClientBrandLogo /> */}

				<h3 style={{ textAlign: "center", marginBottom: "20px", color: "#4d4d4d" }}>{user?.role}</h3>
				<Avatar
					style={{
						marginLeft: "auto",
						marginRight: "auto",

						height: "60px",
						width: "60px"
					}}
					{...stringAvatar(user?.full_name || "Shahed Saifullah")}
				/>
				<p style={{ textAlign: "center", fontSize: "20px" }}>{user?.full_name || "Shahed Saifullah"}</p>
				{/* Sidebar content */}
				<List disablePadding>
					{appRoutes.map((route, index) =>
						route.sidebarProps ? (
							route.child ? (
								<SidebarItemCollapse item={route} key={index} />
							) : (
								<SidebarItem item={route} key={index} />
							)
						) : null
					)}
				</List>
				<div className="logo-column">
					<p style={{ color: "gray", fontStyle: "italic", fontWeight: "bold" }}>Powered By</p>
					<div
						className="logo-content"
						style={{
							borderRadius: "6px",
							cursor: "pointer"
						}}
					>
						<a href="https://scripturesresearch.com/" target="_blank" rel="noreferrer">
							<img
								src={assets.images.company}
								alt="logo"
								style={{
									width: "80%",
									objectFit: "cover",
									borderRadius: "10px"
								}}
							/>
						</a>
					</div>
				</div>
			</Drawer>
		</>
	);
};

export default Sidebar;
