import React, { useCallback, useState } from "react";
import { Grid, Paper, TextField, Box, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { IViewUserProps } from "../../../../../@types/props/ViewUser.props";
import { api } from "../../../../../utils/api";
import SnackbarMessage from "../../../../shared/snackbar/SnackbarMessage";

function FamilyDetailsForm({ user }: IViewUserProps) {
	const queryParams = new URLSearchParams(window.location.search);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const userId = queryParams.get("_id");
	const [familyDetails, setFamilyDetails] = useState({
		fathers_name: user?.fathers_name || "",
		fathers_occupation: user?.fathers_occupation || "",
		mothers_name: user?.mothers_name || "",
		mothers_occupation: user?.mothers_occupation || "",
		no_of_brothers: user?.no_of_brothers || "",
		no_of_sisters: user?.no_of_sisters || "",
		total_family_members: user?.total_family_member || "",
		financial_condition: user?.financial_condition || ""
	});

	const [editable, setEditable] = useState(true);

	const handleFamilyDetailsChange = (event: any) => {
		const { id, value } = event.target;
		setFamilyDetails({ ...familyDetails, [id]: value });
	};

	const handleEditClick = () => {
		setEditable(true);
	};

	const handleSaveClick = useCallback(async () => {
		setEditable(false);
		console.log("save clicked--->", familyDetails);
		const payload = { userDetails: familyDetails, userObjectId: userId };
		console.log("Changes value---->", familyDetails);
		const response = await api.user.updateUserDetails(payload);
		if (response) {
			setSnackbarMessage("Family details Edited successfully!");
			setSnackbarOpen(true);
		}
	}, [familyDetails, userId]);
	return (
		<>
			<div className="edit-btn-container">
				{/* <Button
					onClick={handleEditClick}
					sx={{ marginRight: 1 }}
					variant="contained"
					className="button-violet"
					endIcon={<EditIcon />}
					disabled={editable}
				>
					Edit
				</Button> */}
				<Button
					onClick={handleSaveClick}
					variant="contained"
					className="button-violet"
					endIcon={<SaveIcon />}
					disabled={!editable}
				>
					Save
				</Button>
			</div>
			<h3>Family Details</h3>
			<Paper elevation={3} sx={{ padding: 3, maxWidth: "100%", margin: "auto", paddingLeft: 5 }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6}>
						<Box component="label" htmlFor="fathers_name" sx={{ fontWeight: "bold" }}>
							Father Name:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="fathers_name"
							variant="outlined"
							value={familyDetails.fathers_name}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="fathers_occupation" sx={{ fontWeight: "bold" }}>
							Father Occupation:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="fathers_occupation"
							variant="outlined"
							value={familyDetails.fathers_occupation}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="mothers_name" sx={{ fontWeight: "bold" }}>
							Mother Name:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="mothers_name"
							variant="outlined"
							value={familyDetails.mothers_name}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="mothers_occupation" sx={{ fontWeight: "bold" }}>
							Mother Occupation:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="mothers_occupation"
							variant="outlined"
							value={familyDetails.mothers_occupation}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="no_of_brothers" sx={{ fontWeight: "bold" }}>
							No. of Brothers:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="no_of_brothers"
							variant="outlined"
							value={familyDetails.no_of_brothers}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="no_of_sisters" sx={{ fontWeight: "bold" }}>
							No. of Sisters:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="no_of_sisters"
							variant="outlined"
							value={familyDetails.no_of_sisters}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="total_family_members" sx={{ fontWeight: "bold" }}>
							Total Family Members:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="total_family_members"
							variant="outlined"
							value={familyDetails.total_family_members}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>

					<Grid item xs={6}>
						<Box component="label" htmlFor="financial_condition" sx={{ fontWeight: "bold" }}>
							Financial Condition:
						</Box>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="financial_condition"
							variant="outlined"
							value={familyDetails.financial_condition}
							onChange={handleFamilyDetailsChange}
							disabled={!editable}
						/>
					</Grid>
				</Grid>
				<SnackbarMessage
					snackbarOpen={snackbarOpen}
					snackbarMessage={snackbarMessage}
					severity={snackbarSeverity}
					handleClose={() => setSnackbarOpen(false)}
				/>
			</Paper>
		</>
	);
}

export default FamilyDetailsForm;
