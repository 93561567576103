import { AccountCircle, Logout, LogoutRounded, Person, PersonAdd, Settings } from "@mui/icons-material";
import {
	Avatar,
	Button,
	ClickAwayListener,
	Divider,
	Grow,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	Popper,
	Tooltip
} from "@mui/material";
import React, { useContext, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/authContext/authContext";

const DropdownMenu = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRouteToProfile = () => {
		navigate("/profile");
	};

	const handleLoginLogout = () => {
		console.log("Logout button clicked");
		if (!user) {
			navigate("/login");
		} else {
			localStorage.clear();
			navigate("/login");
		}
	};

	return (
		<div>
			<Tooltip title="Account settings">
				<IconButton
					onClick={handleClick}
					size="small"
					aria-controls={open ? "account-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
				>
					<AccountCircleIcon sx={{ width: 32, height: 32 }} />
				</IconButton>
			</Tooltip>
			<Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
				<MenuItem>
					<ListItemIcon>
						<Person fontSize="small" />
					</ListItemIcon>
					My Profile
				</MenuItem>

				<Divider />

				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					Settings
				</MenuItem>
				<MenuItem onClick={handleLoginLogout}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					{!user ? "Login" : "Logout"}
				</MenuItem>
			</Menu>
		</div>
	);
};

export default DropdownMenu;
